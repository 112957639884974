.cartImageEditor {
  $small: 450px;
  $medium: 768px;
  position: absolute;

  * {
    transition: 0.3s !important;
  }

  top: 0;
  right: 0;
  width: 100%;
  height: 100vh;

  @media screen and (max-width: $medium) {
    height: 130vh;
  }

  background-color: rgb(255, 255, 255);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .previewer {
    flex: 19;

    @media screen and (max-width: $medium) {
      flex: 11;
    }

    width: 100%;
    background-color: black;
    position: relative;
  }

  .controlsWrapper {
    flex: 8;
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 5rem 2rem 5rem;

    @media screen and (max-width: $medium) {
      flex-direction: column;
      padding: 0 1rem 2rem 1rem;
    }

    .leftPart {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      @media screen and (max-width: $medium) {
        width: 100%;
      }

      .sliderWrapper {
        width: 90%;

        * {
          transition: 0s !important;
        }

        p {
          font-size: 0.7rem;
        }
      }

      .controls {
        margin-top: 1rem;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        margin-bottom: -15px;

        button {
          border: none;
          cursor: pointer;
          background-color: rgb(2, 69, 192);
          color: white;
          margin: 5px 0;
          font-size: 0.8rem;
          border-radius: 80px;
          padding: 2px 25px;

          &:hover {
            background-color: rgb(0, 89, 255);
          }
        }
      }
    }

    .rightPart {
      flex: 1;

      .ant-select {
        width: 100% !important;
        margin-bottom: 5px;
      }

      @media screen and (max-width: $medium) {
        width: 100%;
      }

      .label {
        background-color: rgb(121, 121, 121);
        width: 50px;
        color: white;
        border-top-left-radius: 3px;
        border-top-right-radius: 3px;
        text-align: center;
        font-weight: 600;
        font-size: 0.7rem;
      }

      textarea {
        font-size: 0.8rem;
      }
    }

    .actions {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 30px;
      display: flex;
      align-items: center;

      .saveBtn {
        flex: 2;
        height: 30px;
        font-size: 0.8rem;
        font-weight: 600;
        border: none;
        background-color: rgb(0, 173, 0);
        cursor: pointer;
        color: white;

        &:hover {
          background-color: rgb(0, 202, 0);
        }
      }

      .cancelBtn {
        flex: 1;
        height: 30px;
        font-size: 0.8rem;
        font-weight: 600;
        border: none;
        background-color: rgb(173, 0, 0);
        cursor: pointer;
        color: white;

        &:hover {
          background-color: rgb(202, 0, 0);
        }
      }
    }
  }

}