@import "components/design/_variables";

.ant-layout-sider {
  &.main-navigation-sider {
    z-index: 3;
    left: 0;
    position: fixed;
    background: $dark;
    transform: translateX(0);
    transition: transform ease-in-out 0.3s;

    @media (max-width: 767px) {
      &:not(.active) {
        transform: translateX(-$nav-width);
      }
    }

    .ant-layout-sider-children {
      height: 100vh;
      display: flex;
      flex-direction: column;
    }

    .main-navigation {
      scroll-behavior: smooth;
      -webkit-overflow-scrolling: touch;
      height: calc(100vh - 10rem);
      overflow: hidden;
      overflow-y: auto;
    }
  }
}

.ant-layout-header {
  &.main-navigation-header {
    width: 100%;
    height: $nav-header-height;
    padding-left: $nav-width;
    padding-right: 0;
    top: 0;
    left: 0;
    z-index: 2;
    position: fixed;
    background: #D4D5DA;

    transition: padding-left ease-in-out 0.3s;

    @media (max-width: 767px) {
      padding-left: 0;
    }

    >.ant-row {
      height: 100%;

      @media (min-width: 480px) {
        padding-left: $nav-padding;
        padding-right: $nav-padding;
      }

      @media (max-width: 479px) {
        padding-left: $nav-padding-sm;
        padding-right: $nav-padding-sm;
      }
    }
  }
}

.main-navigation-sider-overlay {
  @media (max-width: 767px) {
    width: 100%;
    height: 100%;
    z-index: 2;
    top: 0;
    left: 0;
    position: fixed;
    visibility: hidden;
    opacity: 1;
    background-color: $dark;
    transition: opacity ease-in-out 0.3s;

    &.active {
      opacity: 0.4;
      visibility: visible;
    }
  }
}

.navigation-action {
  .ant-btn {
    display: flex;
    font-size: 1rem;
    line-height: 1.3;

    @media (min-width: 1200px) {
      margin-left: 0.5rem;
    }

    &.ant-btn-text {
      span:not(.anticon) {
        @media (max-width: 1080px) {
          display: none;
        }
      }
    }

    &.no-label {
      display: block;

      .anticon {
        opacity: 1;
        margin-right: 0;
      }
    }

    .anticon {
      opacity: 0.5;
      height: $input-icon-size;
      width: $input-icon-size;
      transition: opacity ease-in-out 0.3s;

      @media (min-width: 1200px) {
        margin-right: 0.125rem;
      }

      svg {
        width: 1.25rem;
        height: 1.25rem;
      }
    }

    &:hover {
      .anticon {
        opacity: 1;
      }
    }
  }
}


.new-sp-sidebar {
  background: #526171 !important;

  .nav-branding {
    padding: 20px 20px 15px !important;
    background: none !important;
    height: auto !important;
    border: 0 !important;

    .sidebar-logo {
      width: 100% !important;
      background: #ffffff;
      border-radius: 10px;
      height: 60px !important;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;
    }
  }

  .main-navigation {
    padding: 0 18px !important;
    background: none !important;

    .ant-menu-submenu {
      min-height: 30px !important;
      position: relative;
      padding: 0 10px !important;
      background: none !important;
      margin-bottom: 10px !important;
      opacity: 1 !important;
      overflow: visible;

      &:before {
        content: "";
        position: absolute;
        left: -20px;
        top: 0;
        width: 5px;
        height: 0;
        background: #fff;
        transition: 0.3s ease;
      }

      .ant-menu-submenu-title {
        padding: 0 !important;
        overflow: visible;

      }

      .anticon {
        margin-right: 15px !important;
        opacity: 1 !important;

        svg {
          width: 18px !important;
        }
      }

      .ant-menu-title-content {
        margin-left: 0 !important;
        font-size: 14px !important;
      }

      .ant-menu-submenu-arrow {
        right: -10px !important;
        opacity: 1 !important;
        color: #fff !important;
      }

      &.ant-menu-item-selected,
      &.ant-menu-submenu-selected,
      &:hover {
        background: none !important;

        &:before {
          height: 100%;
        }
      }
    }

    .ant-menu-item {
      min-height: 0 !important;
      height: 30px !important;
      position: relative;
      padding: 0 10px !important;
      background: none !important;
      margin-bottom: 10px !important;
      opacity: 1 !important;
      overflow: visible;

      &:before {
        content: "";
        position: absolute;
        left: -20px;
        top: 0;
        width: 5px;
        height: 0;
        background: #fff;
        transition: 0.3s ease;
      }

      &.locationDropdown {
        background: #bae0ff !important;
        padding: 0 5px !important;
        border-radius: 10px;
        min-height: 30px !important;
        height: 30px !important;
        margin-bottom: 20px !important;

        &:hover {
          background: #bae0ff !important;

          &:before {
            content: none;
          }
        }
      }

     

      .anticon.ant-menu-item-icon {
        margin-right: 15px !important;
        opacity: 1 !important;

        

        svg {
          width: 18px !important;
        }
      }

      &.ant-menu-item-selected,
      &:hover {
        background: none !important;

        &:before {
          height: 100%;
        }
      }

      .ant-menu-title-content {
        margin-left: 0 !important;
        font-size: 14px !important;
      }
    }
  }
}