.StatusBucket {
    padding: 15px;
    border-radius: 5px;
    background: #F5F5F5;

    &__Row {
        flex-direction: row !important;
        overflow: auto !important;
        flex-flow: row !important;
        padding-bottom: 10px !important;

        &>.ant-col {
            &:last-child {
                .TriggerBox {
                    display: none;
                }

                .connet {
                    &.right {
                        display: none !important;
                    }
                }
            }
        }
    }

    .TriggerBox {
        display: flex;
        align-items: center;
    }

    .ant-row {
        height: 100%;
    }

    &__Triggers {
        border: 0 !important;
        border-radius: 5px !important;
        background: #E6FFFB !important;
        width: 100%;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);

        .trigger-line {
            position: absolute;
            top: 50%;
            width: 25px;
            height: 3px;
            background: #00474F;

            &.left {
                left: -10px;

                &:before {
                    right: 0;
                }
            }

            &.right {
                right: -10px;

                &:before {
                    left: 0;
                }
            }

            &:before {
                width: 5px;
                height: 5px;
                background: #00474F;
                content: "";
                position: absolute;
                border-radius: 5px;
                top: -1px;
            }
        }

        .ant-card-body {
            padding: 8px !important;
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;

            .trigger {
                padding: 5px 5px 5px 20px;
                font-size: 10px;
                margin-bottom: 5px;

                .anticon {
                    font-size: 10px;
                    left: 5px;
                    top: 6px;
                }

                &::before {
                    content: none;
                }
            }

            .TriggerBtn {
                font-size: 10px !important;
                padding: 3px 8px 3px 3px;
                border-radius: 30px !important;
                border: 0;
                font-weight: 600;
                color: #ffffff;
                background: #36CFC9;
                height: 20px;
                display: flex;
                align-items: center;
                margin-top: 10px;

                .anticon {
                    font-size: 14px;
                    display: flex;
                }
            }
        }
    }

    &__Card {
        border-radius: 5px !important;
        border: 0 !important;
        overflow: hidden;
        box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, 0.15);
        z-index: 19;
        height: 100%;

        .ant-card-head {
            padding: 0 10px !important;
            min-height: 35px;
            display: flex;
            background: #E6FFFB;
            border-radius: 0 !important;
            border: 0 !important;

            &-title {
                padding: 0;

                .ant-typography {
                    font-size: 12px;
                    color: #006D75;
                    font-weight: 700;
                    margin: 0;
                    line-height: 15px;
                }
            }
        }

        .ant-card-body {
            padding: 14px 10px 4px !important;

            .ant-list-items {
                .ant-list-item {
                    padding: 0 !important;
                    border: 0 !important;
                    margin-bottom: 10px;

                    .quickAccessList {
                        padding: 4px !important;
                        background: #ffffff !important;
                        box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.2);
                        border-radius: 5px !important;
                        height: 100%;

                        &__Date {
                            width: 42px !important;
                            height: 40px !important;

                            &--Icon {
                                display: none;
                            }

                            &--Day {
                                font-size: 14px !important;
                                line-height: 15px !important;
                                margin-bottom: 2px;
                            }

                            &--Year {
                                font-size: 7px !important;
                                line-height: 9px !important;
                            }

                            label {
                                padding: 0;
                                border-radius: 5px;
                                display: flex;
                                width: 100%;
                                height: 100%;
                                align-items: center;
                                justify-content: center;

                                &::before,
                                &::after {
                                    content: none;
                                }
                            }
                        }

                        &__Content {
                            padding-left: 5px !important;
                            width: calc(100% - 42px);

                            .data-panel {
                                width: 100%;
                            }

                            &--Body {
                                padding: 0 !important;

                                label {
                                    font-size: 10px;
                                    line-height: 11px;
                                    margin-bottom: 0;
                                }

                                p {
                                    font-size: 12px;
                                    line-height: 13px;
                                    height: 13px;
                                    overflow: hidden;
                                    text-transform: capitalize;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.connet {
    position: absolute;
    top: 50%;
    height: 3px;
    width: 10px;
    background: #11464f;
    z-index: 10;

    &:before {
        width: 5px;
        height: 5px;
        background: #00474F;
        content: "";
        position: absolute;
        border-radius: 5px;
        top: -1px;
    }

    &.right {
        right: 0;

        &:before {
            left: 0;
        }
    }

    &.left {
        left: 0px;

        &:before {
            right: 0;
        }
    }
}

.status-block {
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 3px;

    .taskStatus {
        mark {
            padding: 3px 4px !important;
            font-size: 9px !important;
            margin-right: 5px;
            line-height: 6px;
            border-radius: 2px;
            font-weight: 500;
        }
    }
}

.client_icon {
    color: #ffffff !important;
}