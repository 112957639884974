.viewReportTable {
    td.ant-table-cell:first-child {
        display: none;
    }

    th.ant-table-cell:first-child {
        display: none;
    }
}

.viewReportChart__full {
    .pieChartSpace {
        width: 100% !important;
        display: flex;

        .ant-space-item {
            width: 100%;
        }
    }
}