@import "components/design/_variables";

.ant-layout {
  table * {
    max-height: 100%;
  }
}

.calendar-select-container {
  overflow: hidden;
  width: 100%;
  height: 100%;
  transition: all ease-in-out 0.3s;

  .swiper-container,
  .swiper-wrapper,
  .swiper-slide,
  .ant-picker-calendar-full,
  .ant-picker-panel,
  .ant-picker-month-panel,
  .ant-picker-body,
  .ant-picker-content {
    height: 100% !important;
  }
}

.ant-picker-calendar {
  will-change: height;

  &.schedule-calendar-month {
    table {
      thead {
        display: none;
      }
    }

    .calendar-month-label {
      text-transform: uppercase;
      margin-bottom: 0;
      text-align: center;
      font-size: 0.875rem;
      background-color: #f0f0f0;
      padding-top: 0.25rem;
      padding-bottom: 0.25rem;
      margin-bottom: 2px;
    }

    .ant-picker-date-panel {
      .ant-picker-body {
        padding: 0;
      }

      .ant-picker-calendar-date-content {
        height: calc(100% - 28px);
        top: 28px;
        left: 0;
        width: 100%;
        overflow-x: hidden;
        position: absolute;
      }
    }

    .ant-picker-panel {
      height: calc(100% - 29px);

      .ant-picker-date-panel,
      .ant-picker-body,
      .ant-picker-content {
        height: 100%;
      }

      tbody {
        > tr {
          &:first-child {
            .ant-picker-cell {
              .ant-picker-cell-inner {
                border-top: 0;
              }
            }
          }
        }
      }

      .ant-picker-cell {
        opacity: 0.5;
        vertical-align: top;

        &:empty {
          display: none;
        }

        &.ant-picker-cell-in-view {
          cursor: pointer;
          opacity: 1;
        }

        &:hover {
          .ant-picker-cell-inner {
            background-color: $lightest;
          }
        }
      }

      .ant-picker-cell {
        &:not(:last-child) {
          @media (min-width: 768px) {
            border-right: 1px solid $lighter;
          }
        }
      }

      .ant-picker-cell-inner {
        height: 100%;
        margin: 0;
        border-top-width: 1px;
        background-color: transparent;
        overflow: hidden;
        text-align: center;
        transition: background-color ease-in-out 0.3s,
          border-color ease-in-out 0.3s;

        @media (max-width: 767px) {
          border-top-width: 0px;
          font-size: 0.875rem;
        }
      }

      .ant-picker-calendar-date-today {
        border-top-width: 2px;
        background-color: transparent !important;

        .ant-picker-calendar-date-value {
          color: rgba(0, 0, 0, 0.85) !important;
        }
      }

      .ant-picker-cell-selected {
        > div {
          border-top-width: 2px;
        }

        > div:not(.ant-picker-calendar-date-today) {
          border-color: #f0f0f0;
          background-color: transparent;

          .ant-picker-calendar-date-value {
            color: rgba(0, 0, 0, 0.85);
          }
        }
      }
    }
  }

  &.schedule-calendar-month-current {
    .ant-picker-panel {
      .ant-picker-cell-selected {
        .ant-picker-calendar-date-today {
          background-color: #e6f7ff !important;
        }

        .ant-picker-calendar-date {
          border-color: #1890ff !important;
        }

        > div:not(.ant-picker-calendar-date-today) {
          background: #e6f7ff;
        }
      }
    }
  }
}
