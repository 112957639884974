@import "components/design/_variables";
@import "components/design/_ant";
@import "components/design/_typography";
@import "components/design/_lists";
@import "components/design/_buttons";
@import "components/design/_form";
@import "components/design/_modals";
@import "components/design/_drawers";

.ant-select-arrow {
    color: white;
}

.ant-modal-title {
    margin-left: 1.5rem;
}

.ant-btn {
    border-radius: 5px;
}

.filterRadio {
    display: block;
    margin-top: 5px;
}

.search-date-picker {
    width: 100%;
}

.ant-list-empty-text {
    padding: 0;
  }

.taskStatus {
    mark {
        font-size: 11px;
        padding: 5px 10px !important;
        line-height: 14px;
        border-radius: 5px;
    }
}

.task {
    display: flex;
    justify-content: space-between;
}

.phoneNumberTable {
    input[type="text"] {
        @media (max-width: 767px) {
            width: 140px;
        }
    }
}

.ant-drawer-title {
    @media(max-width: 767px) {
        padding-left: 0 !important;
    }
}

.ant-drawer .ant-drawer-header {
    @media(max-width: 767px) {
        padding: 0 20px;

        .ant-drawer-close {
            padding: 0 !important;
        }
    }
}

.ant-row.form-control.isActive {
    padding: 0px 10px 10px 0 !important;

    .form-control-label {
        transform: scale(0.8) translate(0.5rem, -0.375rem) !important;
    }

    .form-control-content {
        background-color: #fafafa;
        padding: 1.5rem 0.5rem 0.5rem 0.5rem;
    }
}

.login-form {
    .ant-row.form-control.isActive {
        padding: 0.25rem 1rem 0 1rem !important;

        .form-control-label {
            transform: scale(0.75) translate(0.25rem, -0.375rem) !important;
        }

        .form-control-content {
            background: none !important;
            padding: 1.25rem 0.25rem 0.25rem 0.5rem;
        }
    }
}

.sticky-action-bar {
    position: sticky;
    top: 0px;
    background: #ffffff;
    z-index: 10;
    padding: 5px 0;
}

.ant-picker.ant-picker-range {
    background: none;
    border: 0;
}

.callWarning {
    padding: 0 75px;
}

.CustomFields {
    padding: 20px 10px;

    &>.ant-col {
        padding: 0 10px;
    }
}

.ant-pagination-total-text {
    float: left;
    font-size: 14px;
    font-weight: 600;
    margin-right: auto;
}