.ant-list.list-notes {
  max-height: 187px;
  overflow: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: auto;
  padding: 0 1rem;
  box-shadow: inset 1px 1px 3px rgba(0, 0, 0, 0.08);
  background-color: #f5f5f5;

  .ant-list-split {
    .ant-list-item {
      border-bottom: 1px solid #eee;
    }
  }
}

.ant-col.statusNote {
  margin: 20px 0 0;
  padding: 20px;
  background: #f5f5f5;
  box-shadow: inset 1px 1px 3px rgb(0 0 0 / 8%);

  .ant-typography {
    font-size: 13px;
  }
}