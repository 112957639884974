@import "components/design/_variables";

.ant-badge.badge-overflow {
  overflow: hidden;
  width: 100%;
  display: block;
  text-overflow: ellipsis;
  top: 0;
  left: 0;
  height: 46px;
  padding: 3px 10px;
  z-index: 2;
  position: absolute;
  background-color: lighten(#faad14, 45%);

  &:hover,
  &:active,
  &:focus {
    background-color: lighten(#faad14, 41%);
  }

  &.isSuccess {
    background-color: lighten(#52c41a, 45%);

    &:hover,
    &:active,
    &:focus {
      background-color: lighten(#52c41a, 41%);
    }
  }

  .ant-badge-status-dot {
    top: 0;
    left: 0;
    height: 2px;
    width: 100%;
    border-radius: 0;
    position: absolute;
  }

  .ant-badge-status-text {
    font-size: 0.675rem;
    text-overflow: ellipsis;
    margin-left: 0;
    line-height: 1.2;
    display: block;
  }
}

.time-select-list {
  height: 100%;

  div {
    height: 100%;
  }

  .ant-list-items {
    display: flex;
    height: 100%;
    justify-content: flex-start;
  }

  .ant-list-item {
    cursor: pointer;
    position: relative;
    flex: 1 1 auto;
    padding-top: 0;
    padding-bottom: 0;
    margin-bottom: 0 !important;
    border-bottom: 0 !important;

    .isFirst {
      z-index: 9 !important;
    }

    .time-detail-active & {
      border-color: rgba(0,0,0,0.1);
    }
  }
  .ant-list-empty-text {
    display: none;
  }

  .ant-row {
    > div {
      &:empty {
        display: none;
      }
    }
  }
}

.btn-time-select {
  position: absolute;
  border: 0;
  padding: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.time-select-container {
  overflow: hidden;
  overflow-y: auto;
  width: 100%;
  z-index: 1;
  left: 0;
  -webkit-overflow-scrolling: auto;
  outline: none;
  position: relative;

  .time-detail {
    padding-top: 0;
    padding-bottom: 0;
    background-color: #fff;
    transition: background-color ease-in-out 0.3s;

    &.time-detail-active {
      background-color: $primary-lighter;

      > .ant-card {
        > .ant-card-body {
          .time-of-day-col {
            &::before {
              background-color: $primary;
            }
          }
        }
      }
    }

    > .ant-card {
      background-color: transparent;
      border-top: 0;

      .ant-card-body {
        padding: 0;

        .time-of-day-col {
          flex: 0 0 6rem;
          max-width: 6rem;
          height: 44px;
          text-align: right;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          padding-right: 1.25rem;
          border-right: 1px solid $lighter;
          position: relative;

          &::before {
            content: "";
            display: block;
            width: 2px;
            height: 100%;
            top: 0;
            left: 0;
            z-index: 2;
            position: absolute;
            background-color: transparent;
          }

          .time-label {
            opacity: 0.25;
            font-size: 0.75rem;
            text-transform: uppercase;
            display: inline-block;
          }
        }

        .time-entries-col {
          flex-grow: 1;
          max-width: 100%;
        }
      }
    }
  }
}