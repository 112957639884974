.ant-card.dashboard-tasks,
.ant-card.dashboard-calls,
.ant-card.dashboard-notifications,
.ant-card.dashboard-sessions {
	height: 100%;

	.ant-card-body {
		height: auto;
		max-height: 400px;
		overflow-y: scroll;
		// padding: 0px;
	}

	.ant-list-split .ant-list-item:last-child {
		border-bottom: 1px solid #f0f0f0;
	}
}

.ant-card.dashboard-tasks,
.ant-card.dashboard-calls {
	.ant-card-body {
		padding: 0px;
	}
}

.ant-card.dashboard-notifications {
	.ant-card-body {
		padding: 24px 0px;
	}
}

.main-content-pads {
	background-color: #ffffff;
	min-height: calc(100vh - 70px);
	padding: 20px !important;

	&>.ant-card {
		min-height: calc(100vh - 100px);

		.ant-card-body {
			padding: 15px;

			.ant-pro-table {
				.ant-card-body {
					padding: 0;
				}

				.ant-pro-table-list-toolbar-container {
					padding: 0 0 10px;
				}
			}
		}

		.ant-card-head {
			padding: 0 15px;
		}
	}
}

.statistic-container {
	display: flex;
	flex-wrap: wrap;
	align-items: flex-start;
}

.mini-chart-tooltip {
	padding: 10px;

	span {
		margin-left: 10px;
	}
}

.dash-spinner {
	width: 100%;
	text-align: center;
}

.search-page-content {
	.ant-select {
		min-width: 100px !important;
		width: 100%;
	}
}

.dashboard-tasks {

	.taskListItem {
		&__Action {
			justify-content: flex-end !important;

			.ant-list-item-check {
				padding: 5px 5px;
				font-size: 12px;
			}
		}

		&__Data {
			&--Title {
				display: flex;
				font-size: 13px;
				line-height: 18px !important;
				max-height: 40px !important;
				overflow: hidden;
			}

			&--Status {
				.taskStatus {
					mark {
						font-size: 10px;
						padding: 3px 10px !important;
					}
				}
			}
		}

		&__Date {
			width: 80px;
			height: 73px;

			&--Day {
				font-size: 20px;
			}

			&--MonthYear {
				font-size: 12px;
			}

			&--Due {
				font-size: 8px;
				height: 16px;
			}
		}
	}

	.ant-card-extra {
		display: flex;
		align-items: center;
	}

	.taskButton {
		border: 0 !important;
		margin-right: 10px !important;
		height: 28px !important;
		display: flex !important;
		align-items: center;
		color: rgb(0, 150, 139) !important;

		.anticon {
			display: flex;
		}

		&:hover {
			background: #006d75 !important;
			color: #fff !important;
		}
	}

}


.calendarButton {
	border: 0 !important;
	margin-right: 10px !important;
	height: 28px !important;
	display: flex !important;
	align-items: center;
	color: #0958d9 !important;

	.anticon {
		display: flex;
	}

	&:hover {
		background: #001d66 !important;
		color: #fff !important;
	}
}

.deleteButton {
	border: 0 !important;
	height: 28px !important;
	display: flex !important;
	align-items: center;
	color: #391085 !important;

	.anticon {
		display: flex;
	}

	&:hover {
		background: #120338 !important;
		color: #fff !important;
	}
}

.ant-card-extra {
	display: flex;
	align-items: center;
}

.callButton {
	border: 0 !important;
	margin-right: 10px !important;
	height: 28px !important;
	display: flex !important;
	align-items: center;
	color: #fa8c16 !important;

	.anticon {
		display: flex;
	}

	&:hover {
		background: #612500 !important;
		color: #fff !important;
	}
}

.pad-top-0 {
	padding-top: 0 !important;
}

.Dashboard-Welcome {
	min-height: 0 !important;
	margin-bottom: 20px !important;
	border: 0 !important;

	.ant-card-body {
		padding: 0 !important;

		h4.ant-typography {
			font-size: 24px;
			font-weight: 700;
			margin: 0;
			line-height: 24px;
			margin-bottom: 5px;
		}

		span.ant-typography {
			font-size: 16px;
			font-weight: 300;
			color: #3A3A3A;
			line-height: 14px;
		}
	}

}

.Dashboard-Layout {
	height: calc(100vh - 111px);

	@media(max-width: 1024px) {
		height: auto;
	}

	&__Left {
		width: 35%;
		height: 100%;

		@media(max-width: 1024px) {
			width: 100%;
			height: auto;
		}
	}

	&__Right {
		width: 65%;
		height: 100%;

		@media(max-width: 1024px) {
			width: 100%;
			height: auto;
		}

		&--Row {
			padding: 0 10px;
			margin-top: 20px !important;
			height: calc(50% - 10px) !important;

			@media(max-width: 1024px) {
				height: auto;
			}
		}
	}

	&__Card {
		border: 0 !important;
		background: #f5f5f5 !important;
		border-radius: 10px !important;
		overflow: hidden !important;

		&.Upcoming-Schedule {
			
			background: #7CA3A7 !important;
			height: 100% !important;

			@media(max-width: 1024px) {
				height: auto !important;
				margin-bottom: 20px;
			}

			.viewAllButton {
				background: #cedbde;
				color: #00256E;
			}

			&>.ant-card-body {
				background-color: #ACC4C8;
				padding-top: 1rem;
			}

		}

		&.Home-Tasks {
			background: #93B4C9 !important;
			height: calc(50% - 10px) !important;
			margin-bottom: 10px;

			@media(max-width: 1024px) {
				width: 100%;
				height: auto !important;
				margin-bottom: 20px;
			}

			.viewAllButton {
				background: #e2eaf0;
				color: #612500;
			}

			.ant-list-items {
				display: flex;
				flex-wrap: wrap;
				margin: 0 -10px;
			}
			& > .ant-card-body {
				background-color: #BDD2E0;
				padding-top: 1rem;
			}
		}

		&.Home-PhoneCalls {
			background: #E59F7E !important;
			height: 100% !important;
			margin-bottom: 10px;
			width: calc(50% - 10px);
			margin-right: 20px;

			@media(max-width: 1024px) {
				width: 100% !important;
				height: auto !important;
				margin-right: 0;
				margin-bottom: 20px;
			}

			.viewAllButton {
				background: #f9e4da;
				color: #030852;
			}

			.ant-list-items {
				display: flex;
				flex-wrap: wrap;
				margin: 0 -10px;
			}

			& > .ant-card-body {
				background-color: #F2C6AD;
				padding-top: 1rem;
			}
		}

		&.Home-Notifications {
			background: #ABB59E !important;
			height: 100% !important;
			margin-bottom: 10px;
			width: calc(50% - 10px);

			@media(max-width: 1024px) {
				width: 100% !important;
				height: auto !important;
			}

			.viewAllButton {
				background: #E1EBFF;
				color: #030852;
			}

			.ant-list-items {
				display: flex;
				flex-wrap: wrap;
			}

			.ant-card-body {
				height: 100% !important;
			}

			& > .ant-card-body {
				background-color: #CED3C4;
				padding-top: 1rem;
			}
		}

		.ant-card-head {
			min-width: 0;
			margin: 0;
			padding: 5px 10px;
			border: 0;

			&-title {
				padding: 0;
			}
		}

		&--Title {
			padding: 0 !important;
			

			h3.ant-typography {
				font-size: 15px;
				font-weight: 700;
				margin: 0;
				color: white;
			}

			span.ant-typography {
				font-size: 12px;
				font-weight: 500;
				color: #727272;
				line-height: 13px;
			}
		}

		.ant-card-body {
			padding: 0 20px;
			height: calc(100% - 120px);
			overflow: auto;
		}

		.ant-card-actions {
			padding: 20px;
			background: none !important;
			border: 0;

			li {
				margin: 0;
			}
		}

		.viewAllButton {
			cursor: pointer;
			padding: 0 15px !important;
			height: 30px !important;
			font-size: 13px !important;
			display: flex;
			align-items: center;
			justify-content: center;
			margin: auto;
			border: 0 !important;
			border-radius: 10px !important;
			transition: 0.3s ease;

			&:hover {
				opacity: 0.8;
			}
		}
	}
}

.upcoming-schedule {
	padding: 0 10px !important;
	height: 51px;
	border-radius: 10px;
	background: #FFFFFF;
	display: flex;
	align-items: center;
	overflow: hidden;
	margin-bottom: 20px;
	flex-wrap: wrap;

	&__icon {
		padding: 0 !important;
		width: 30px;
		height: 30px;
		background: #E6F7FF;
		border-radius: 15px;
		align-items: center;
		display: flex;
		justify-content: center;
		color: #002766;
		font-size: 14px;
	}

	&__Detail {
		cursor: pointer;
		width: calc(100% - 30px);
		padding: 0 0 0 10px !important;
		display: flex;
		flex-direction: column;

		h4.ant-typography {
			font-size: 14px;
			font-weight: 400;
			color: #00256E;
			margin: 0;
			height: 20px;
			overflow: hidden;
			margin-bottom: 2px;
			line-height: 18px;
		}

		span.ant-typography {
			font-size: 12px;
			font-weight: 700;
			font-style: italic;
			line-height: 11px;
			margin-top: 0px;
		}
	}
}

.homeTask {
	width: 25%;
	padding: 0 10px;

	@media(max-width: 1024px) {
		width: 33.33%;
		padding: 5px 10px;
	}

	@media(max-width: 993px) {
		width: 50%;
	}

	@media(max-width: 540px) {
		width: 100%;
	}

	&Inner {
		background: #fff;
		display: flex;
		flex-direction: column;
		background: #fff;
		border-radius: 10px;
		padding: 15px 0 !important;
		height: 100%;
	}

	&__Date {
		padding: 0 15px !important;
		display: flex;
		align-items: center;
		justify-content: center;

		&--Round {
			width: 100px;
			height: 100px;
			border-radius: 50px;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			background: #FFF7E6;
		}

		&--Day {
			font-size: 32px !important;
			line-height: 18px !important;
			font-weight: 800 !important;
			color: #612500 !important;
			margin: 0 0 9px !important;
		}

		&--Month {
			text-transform: uppercase;
			font-size: 14px !important;
			color: #612500 !important;
			line-height: 14px !important;
		}

		&--Label {
			font-size: 10px !important;
			font-weight: 700 !important;
			color: #000000 !important;
			line-height: 10px !important;
			margin-top: 2px;
		}
	}

	&__Details {
		padding: 15px 15px 0 !important;
		display: flex;
		flex-direction: column;
		align-items: center;

		&--Title {
			font-size: 14px !important;
			font-weight: 700 !important;
			text-align: center !important;
			line-height: 18px !important;
			color: #000000 !important;
		}

		&--Status {
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			justify-content: center;
			padding: 0 !important;
			width: 100%;
			margin-bottom: 15px;

			.taskStatus {
				mark {
					font-size: 9px;
					width: 57px !important;
					height: 15px !important;
					color: #ffffff;
					background: #FAAD14;
					border-radius: 10px;
					margin: 0 5px;
					padding: 0 !important;
					display: flex;
					justify-content: center;
					align-items: center;
				}

				&.red {
					mark {
						background: #D4380D !important;
					}
				}
			}
		}
	}

	&__Action {
		padding: 0 10px !important;
		display: flex !important;
		justify-content: center;
		width: 100%;
		&--Marked {
			margin: auto;
			height: 20px;
			background: #612500;
			border-radius: 10px;
			padding: 0 15px;
			font-weight: 600;
			color: #ffffff !important;
			position: relative;
			font-size: 10px !important;
			display: flex !important;
			align-items: center !important;
			justify-content: center !important;
			line-height: 10px !important;

			&.completed {
				background: #000 !important;
			}

			.ant-checkbox {
				position: absolute;
				left: 0;
				top: 0;
				width: 100%;
				height: 100%;
				opacity: 0;
			}
		}
	}
}

.PhoneCall {
	display: flex;
	flex-wrap: wrap;
	width: 100%;
	padding: 0 10px !important;
	align-items: center;
	margin-bottom: 20px;

	&__Icon {
		width: 40px;
		height: 40px;
		border-radius: 20px;
		background: #ADC6FF;
		font-size: 20px;
		color: #030852;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	&__Details {
		background: #ffffff;
		display: flex;
		align-items: center;
		cursor: pointer;
		width: calc(100% - 50px);
		margin-left: 10px;
		height: 50px;
		padding: 0 10px !important;

		&--text {
			display: flex;
			flex-direction: column;
			justify-content: center;
			padding: 0 10px 0 0 !important;
			max-width: calc(100% - 35px);

			h3.ant-typography {
				font-size: 16px !important;
				line-height: 16px !important;
				color: #030852 !important;
				font-weight: 700 !important;
				margin-bottom: 2px !important;
				height: 16px;
				overflow: hidden;
			}

			span.ant-typography {
				font-size: 11px !important;
				line-height: 11px !important;
				color: #5C5C5C !important;
				font-weight: 400 !important;
				margin-bottom: 0 !important;
				text-transform: capitalize;
				height: 12px !important;
				overflow: hidden;
			}
		}

		&--Mark {
			position: relative;
			z-index: 10;
			width: 35px;
			height: 35px;
			border-radius: 18px;
			background: #10239E;
			margin-left: auto;
			padding: 0 !important;

			.ant-checkbox-wrapper {
				width: 100%;
				height: 100%;
				border-radius: 50%;
				display: flex;
				align-items: center;
				justify-content: center;

				span {
					padding: 0 !important;
					height: 18px;
				}

				.ant-checkbox {
					position: absolute;
					left: 0;
					top: 0;
					width: 100%;
					height: 100%;
					opacity: 0;
				}

				.anticon {
					font-size: 16px;
					color: #fff;
				}
			}
		}
	}
}



.PhoneCallRow {
	width: 100%;
}

.Notification {
	padding: 0 7.5px !important;
	border: 1px solid #00474F;
	width: 100%;
	margin-bottom: 20px;
	border-radius: 10px;
	height: 6.5rem;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	overflow: hidden;
	overflow-y: scroll;

	&__Detail {
		width: 100%;
		padding: 0 0 0 0 !important;
		display: flex;
		flex-direction: column;

		h3.ant-typography {
			font-size: 14px !important;
			font-weight: 600 !important;
			margin: 0 !important;
			text-transform: capitalize;
		}
	}

	&__Delete {
		width: 30px;
		height: 30px;
		border: 1px solid #00474F;
		border-radius: 30px;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 16px;
		color: #00474F;
		transition: 0.3s ease;

		&:hover {
			background: #00474F;
			color: #fff;
			cursor: pointer;
		}
	}
}

.Statistics {
	display: flex !important;
	flex-wrap: wrap;
	justify-content: flex-end;
	align-items: center;

	.ant-statistic {
		background: #f5f5f5;
		color: #000000;
		padding: 0;
		border-radius: 10px;
		height: 50px;
		display: flex;
		align-items: center;
		justify-content: center;
		overflow: hidden;
		margin: 0 5px;

		@media(max-width: 1200px) {
			margin: 0 5px 10px;
		}

		&.Statistics__Sales {
			background: #cedbde;
			color: black;

			.ant-statistic-title {
				color: black;
			}

			.ant-statistic-content {
				background: #92b0b6;
			}

			.ant-statistic-content-value {
				color: black;
			}
		}

		&.Statistics__Receipts {
			background: #f9e4da;
			color: black;

			.ant-statistic-title {
				color: black;
			}

			.ant-statistic-content {
				background: #f1c5ad;
			}

			.ant-statistic-content-value {
				color: black;
			}
		}

		&.Statistics__Bookings {
			background: #e2eaf0;
			color: black;

			.ant-statistic-title {
				color: black;
			}

			.ant-statistic-content {
				background: #bed1df;
			}

			.ant-statistic-content-value {
				color: black;
			}
		}

		&.Statistics__Sessions {
			background: #e9eae3;
			color: black;

			.ant-statistic-title {
				color: black;
			}

			.ant-statistic-content {
				background: #ced2c3;
			}

			.ant-statistic-content-value {
				color: black;
			}
		}

		&-title {
			margin: 0 !important;
			font-size: 13px;
			line-height: 14px !important;
			color: #000;
			padding: 0 15px;
		}

		&-content {
			display: flex;
			height: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 0 14px;
			background: #e4e4e4;

			&-value {
				color: #000000;
				font-weight: 700;
				margin: 2px 0 0 !important;
				font-size: 20px !important;
				line-height: 19px !important;
			}
		}
	}
}