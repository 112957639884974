.ant-form {
  .ant-form-item {
    &.checkedInline {
      width: 100%;
      -ms-flex-pack: justify;
      justify-content: space-between;

      &:not(:last-child) {
        margin-bottom: 0.75rem;
      }

      > .ant-col {
        @media (max-width: 575px) {
          -ms-flex: 0 0 auto;
          flex: 0 0 auto;
          max-width: auto;
        }

        &.ant-form-item-label {
          -ms-flex: 0 0 12.5rem;
          flex: 0 0 12.5rem;
          max-width: 12.5rem;
          text-align: left;
        }
      }
    }
  }
}
