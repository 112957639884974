@import "components/design/_variables";

.calendar-week-container {
  background-color: #fff;

  @media (min-width: 768px) {
    border-bottom: 1px solid $light;
  }

  .swiper-container,
  .swiper-wrapper,
  .swiper-slide {
    height: 100% !important;
    margin: 0;
    padding: 0;
  }

  .calendar-week-list {
    height: 100%;

    > .ant-spin-nested-loading {
      height: 100%;

      > .ant-spin-container {
        height: 100%;

        > .ant-list-items {
          display: flex;
          flex-wrap: wrap;
          align-items: stretch;
          height: 100%;

          & .calendar-day-view {
            max-height: 5rem;
          }

          > .ant-list-item {
            border-bottom: 0;
            flex-basis: 1;
            // flex-direction: row;
            flex-grow: 1;
            width: 14.28%;
            height: 48rem;
            text-align: center;
            align-content: center;
            justify-content: center;

            @media (max-width: 767px) {
              font-size: 0.875rem;
            }

            &:not(:last-child) {
              @media (min-width: 768px) {
                border-right: 1px solid $lighter;
              }
            }

            &.ant-list-item-active {
              color: $primary;
              border-top: 2px solid $primary;
              background-color: $primary-lighter;
              font-weight: bold;
            }

            &.ant-list-item-muted {
              opacity: 0.3;
            }
          }
        }
      }
    }
  }
}
