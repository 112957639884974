.galleryHeader {
  $small: 450px;
  $medium: 768px;
  display: flex;
  align-items: center;
  padding: 0px 20px;
  * {
    transition: 0s!important;
  }
  @media screen and (max-width: $medium) {
    flex-direction: column;
  }
  .headerRightPart {
    flex: 4;
    @media screen and (max-width: $medium) {
      width: 92%;
      margin-top: 1.5rem;
    }
    .searchWrapper {
      position: relative;
      width: 100%;
      .searchInput {
        width: 100%;
        border: 1px solid rgb(233, 233, 233);
        background-color: rgb(240, 240, 240);
        outline: none;
        border-radius: 5px;
        padding: 5px 25px 5px 10px;
        &:focus {
          border: 1px solid rgb(97, 113, 255);
        background-color: rgb(251, 251, 251);
        }
      }
      .searchIcon {
        position: absolute;
        top: 6px;
        right: 15px;
      }
    }
  }
  .headerLeftPart {
    flex: 7;
    @media screen and (max-width: $medium) {
      margin-top: 2rem;
    }
    .actionsWrapper {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      .action {
        padding: 11px 15px;
        border-radius: 45px;
        font-size: 1.4rem;
        cursor: pointer;
        .filled {
          display: none;
        }
        &:hover {
          color: rgb(77, 77, 255);
        }
        &.selected {
          color: rgb(255, 255, 255);
          background-color: rgb(77, 77, 255);
          box-shadow: 0px 0px 30px rgba(0,0,0,0.2);
          .filled {
            display: unset;
          }
          .outline {
            display: none;
          }
        }
      }
      .ant-badge {
        font-size: 1.4rem;
      }
    }
  }

  // strong {
  //   display: inline-block;
  //   margin-top: 20px;
  // }

}

.galleryContent {
  width: 100%;
  margin-top: 2rem;
  height: calc(100vh - 18rem);
  overflow-y: scroll;

  .dropzone {
    text-align: center;
    padding: 20px;
    border: 3px dashed #eeeeee;
    background-color: #fafafa;
    color: #bdbdbd;
    margin: 0px 20px 10px;
  }

}

/* width */
::-webkit-scrollbar {
  width: 3px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #888; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}