.printWrapper {
    &.hide {
        display: none;
    }
}
  
.printInvoiceHeadTitle {
    font-size: 26px !important;
    line-height: 26px;
    font-weight: 700;
    margin: 0;
    color: #096dd9;
}

.printInvoiceRow {
    padding: 15px 40px;
    width: 100%;
}

.clientInfo {
    padding-top: 0 !important;
}

.printInvoiceTitle {
    display: flex;
    // position: relative;
    background-color: #096dd9;
    color: #fff !important;
    margin: 0;
    padding: 5px 10px;
    font-size: 16px;
    font-weight: 700;
    width: 100%;
}

.printInvoice {
    display: inline-block;
    width: 100%;
    font-family: Arial, Helvetica, sans-serif;
    color: #000000;
    padding-bottom: 40px !important;
    &__Head {
        width: 100%;
        background: #f8f8f8;
        padding: 15px 30px;
        td {
            vertical-align: top;
            font-size: 15px;
            padding: 10px 40px;
            background: #f8f8f8;
            border: 0 !important;
            strong {
                font-weight: 700;
            }
            p {
                margin: 0 !important;
                &:first-child {
                    font-weight: 700;
                }
            }
            h3 {
                font-size: 30px;
                line-height: 26px;
                font-weight: 700;
                margin: 0;
                color: #096dd9;
            }
            &:last-child {
                text-align: right;
            }
        }
    }
    &__Client {
        width: 100%;
        color: #000000;
        font-family: Arial, Helvetica, sans-serif;
        td {
            padding: 10px 0px !important;
            font-size: 15px;
            vertical-align: top;
            strong {
                font-weight: 700;
            }
            &:last-child {
                text-align: right;
            }
        }
    }
    &__Title {
        position: relative;
        padding: 10px 20px 0 !important;
        label {
            display: flex;
            position: relative;
            background-color: #096dd9;
            color: #fff;
            margin: 0;
            padding: 5px 10px;
            font-size: 18px;
            font-weight: 700;
        }
    }
    .textRight {
        text-align: right !important;
    }
    &__Details {
        width: 100%;
        color: #000000;
        font-family: Arial, Helvetica, sans-serif;
        th {
            padding: 5px 10px !important;
            font-size: 15px;
            vertical-align: top;
            &:nth-child(4n), &:last-child {
                text-align: right;
            }
        }
        td {
            padding: 5px 10px !important;
            font-size: 15px;
            vertical-align: top;
            &:nth-child(4n), &:last-child {
                text-align: right;
            }
        }
    }
}

.sign__line {
    display: inline-block;
    width: 150px;
    height: 30px;
    border-bottom: 1px solid #000;
    margin: 0 10px;
}

.printImage {
    margin-top: 15px;
    width: 50%;
    &__Media img {
        border: 1px solid #000;
        min-height: 100px;
    }
}

.paragraph {
    margin: 0 !important;
    display: flex;
    label {
        width: 320px;
        display: flex;
        justify-content: flex-end;
        padding-right: 10px;
        font-size: 12px !important;
    }
    strong {
        width: 250px !important;
        display: flex;
        font-size: 12px !important;
        text-align: left;
    }
}

.table-row-first {
    width: 100px !important;
}

.printInvoiceFooter {
    position: fixed; 
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 10px 40px;
    background: #ffffff;
    display: flex;
}

.fullWidth {
  width: 100%;
}

.noImage {
    display: flex !important;
    width: 130px;
    height: 100px;
    border: 1px solid #000;
    align-items: center !important;
    justify-content: center !important;
}

.noMargin {
    margin: 0 !important;
}