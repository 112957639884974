.tabSelectArea {
    margin-bottom: 13px;
    .tabSelectBox {
        width: 200px !important;

        .ant-select-selector {
            border: 0 !important;
            background: #f5f5f5 !important;
            box-shadow: none !important;
        }
    }
}

.ant-tabs-tabpane {
    .ant-pro-card-body {
        padding: 0;
    }
}