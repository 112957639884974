.ant-drawer {
  &.ant-drawer-right {
    &.ant-drawer-open {
      .ant-drawer-content-wrapper {
        box-shadow: $card-shadow;
      }
    }
  }

  .ant-drawer-header {
    padding: 0 $nav-padding;
    border-radius: 0;
    border-bottom: 0;
    border-bottom: 1px solid $border-color-default;
    height: $nav-header-height;
    flex: 0 0 $nav-header-height;

    .ant-drawer-title {
      height: 100%;
      padding-left: 3rem;

      @media (max-width: 479px) {
        padding-left: 2rem;
      }

      .ant-row {
        align-items: center;
        height: 100%;
      }

      h1,
      h2,
      h3 {
        margin-bottom: 0;
        font-size: $title-size-1;
        font-weight: 400;

        @media (max-width: 479px) {
          font-size: $title-size-1-sm;
          width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }

      .ant-btn {
        span:not(.anticon) {
          @media (max-width: 479px) {
            display: none;
          }
        }

        &:not(.btn-action) {
          @media (max-width: 479px) {
            top: 2px;
            position: relative;
          }
        }
      }

      .ant-btn:not(.btn-action) {
        display: flex;
        font-size: 1rem;
        line-height: 1.3;

        .anticon {
          opacity: 0.5;
          width: 1.5rem;
          transition: opacity ease-in-out 0.3s;

          @media (min-width: 1200px) {
            margin-right: 0.125rem;
          }

          svg {
            width: 1.25rem;
            height: 1.25rem;
          }
        }

        &:hover {
          .anticon {
            opacity: 1;
          }
        }
      }
    }

    .ant-drawer-close {
      // left: 0;
      // right: auto;
      // top: 50%;
      // padding-left: $nav-padding;
      // transform: translateY(-50%);

      // @media (max-width: 479px) {
      //   padding-left: $nav-padding-sm;
      // }

      .anticon {
        svg {
          width: 1.25rem;
          height: 1.25rem;
        }
      }
    }
  }

  .ant-drawer-body {
    padding: 0;

    .form-group {
      padding: $form-group-padding $nav-padding;
      border-bottom: 1px solid $lighter;

      @media (max-width: 479px) {
        padding: $form-group-padding-sm $nav-padding-sm;
      }

      >div:last-child {
        margin-bottom: 0;

        .form-control {
          margin-bottom: 0;
        }
      }
    }
  }

  &.drawer-padded {
    .ant-drawer-body {
      padding: $form-group-padding $nav-padding;

      @media (max-width: 479px) {
        padding: $form-group-padding-sm $nav-padding-sm;
      }
    }
  }
}

.session-drawer {
  .ant-drawer-content-wrapper {
    box-shadow: $card-shadow;
    width: calc(100vw - 240px) !important;

    @media screen and (max-width: 768px) {
      width: 100vw !important;
    }
  }
}