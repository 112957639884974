.ant-card-actions {
  .text-hover-red:hover {
    transition: all 0.5s;
    color: red;
  }
}
.bg-white {
  position: absolute;
  top: 0;
  left: 0;
  background-color: white;
  z-index: -100;
  height: 100%;
  width: 100%;
}
