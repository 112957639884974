
.folderManagerModalWrapper {

  .ant-modal-footer {
    display: none !important;
  }
}

.folderModalContentWrapper {
  $small: 450px;
  $medium: 768px;
  height: 40vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: $medium) {
    flex-direction: column;
    height: 60vh;
  }
  .imageViewer {
    flex: 4;
    border-radius: 7px;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      // width: 50%;
      max-height: 30vh;
      border: 1px solid black;
      box-shadow: 2px 2px 18px rgba(0,0,0,0.1);
    }
  }

  .folderManage {
    flex: 4;
    padding: 0 25px;
    h3 {
      margin-bottom: 25px;
    }
    @media screen and (max-width: $medium) {
      margin-top: 3rem;
      width: 100%;
      padding: 0 5px;
    }
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .folderSelector {
      width: 100%;
      .react-dropdown-select-dropdown.react-dropdown-select-dropdown-position-bottom {
        width: 100%;
      }
    }

    .folderUpdateBtn, .folderUpdateBtnLoader {
      margin-top: 2.3rem;
      border: none;
      font-weight: 600;
      background-color: rgb(0, 68, 255);
      color: white;
      padding: 8px 65px 6px 65px;
      font-size: 0.8rem;
      cursor: pointer;
      box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.15);
      text-align: center;
      border-radius: 4px;

      span {
        font-size: 1rem;
      }

      &:hover {
        background-color: rgb(0, 57, 212);
      }
    }
    .folderUpdateBtnLoader {
      background-color: rgba(0, 53, 199, 0.465)!important;
      cursor: progress;
    }
  }
}
.folderManagerModalWrapper {
  .ant-modal-header {
    background-color: rgb(41, 41, 41)!important;
    * {
      color: white!important;
    }
  }
  .ant-modal-close-x {
    color: red!important;
  }
}

.ant-modal-close {
  left: 0;
}