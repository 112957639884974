.galleryFolderViewWRapper {
  padding-top: 2rem;
  padding-right: 8px;

  .site-collapse-custom-panel {
    span {
      padding-right: 15px;
    }
  }

  .ant-collapse-header {
    color: rgb(7, 7, 7) !important;

  }

  .ant-collapse-item.ant-collapse-item-active.site-collapse-custom-panel {
    .ant-collapse-header {
      padding: 28px 16px 30px 40px;
    }
  }

  .ant-collapse-item.site-collapse-custom-panel {
    margin-bottom: 2rem;
    border: none;
    border-left: 5px solid rgb(0, 0, 0);
    background-color: rgb(255, 255, 255);
    // border-radius: 5px !important;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  }

  .ant-collapse-borderless {
    background-color: #ffffff;
    border: 0;
  }
}