@import "components/design/_variables";

.calendar-year-dropdown {
  top: -3px;
  left: -26px;
  z-index: 4;
  position: absolute;
  background-color: #fff;
  border-bottom-left-radius: $boder-radius;
  border-bottom-right-radius: $boder-radius;
  box-shadow: $card-shadow;

  @media (min-width: 1160px) {
    width: 900px;
  }

  @media (min-width: 992px) and (max-width: 1159px) {
    width: 700px;
  }

  @media (max-width: 991px) {
    width: 530px;
  }

  .calendar-year-nav {
    display: flex;
    height: $nav-header-height;
    padding-left: $nav-padding;
    padding-right: $nav-padding;
    border-bottom: 0;

    @media (max-width: 767px) {
      padding-left: $nav-padding-sm;
      padding-right: $nav-padding-sm;
    }
  }

  .calendar-year-full {
    padding-left: $nav-padding;
    padding-right: $nav-padding;
    padding-bottom: $nav-padding;

    @media (max-width: 767px) {
      padding-left: $nav-padding-sm;
      padding-right: $nav-padding-sm;
      padding-bottom: $nav-padding-sm;
    }
  }
}

.calendar-year-dropdown-overlay {
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 3;
  position: absolute;
}
