.cartPhotoSwitcherOuterWrapper {
  width: 100%;
  height: 100vh;
  position: absolute;
  background-color: red;
  z-index: 9999999;
  top: 0;
  left: 0;
  .imageViewerWrapper {
    width: 100%;
    height: 100vh;
    overflow-y: scroll;
    background-color: rgb(8, 8, 8);
  }
  .actions {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 30px;
    display: flex;
    align-items: center;

    .saveBtn {
      flex: 2;
      height: 30px;
      font-size: 0.8rem;
      font-weight: 600;
      border: none;
      background-color: rgb(0, 173, 0);
      cursor: pointer;
      color: white;

      &:hover {
        background-color: rgb(0, 202, 0);
      }
    }

    .cancelBtn {
      flex: 1;
      height: 30px;
      font-size: 0.8rem;
      font-weight: 600;
      border: none;
      background-color: rgb(173, 0, 0);
      cursor: pointer;
      color: white;

      &:hover {
        background-color: rgb(202, 0, 0);
      }
    }
  }
  .masonryGridWrapper {
    * {
      transition: 0.1s !important;
    }
  
    //variables
    $primary: #5408FF;
    $secondary: #FF08FF;
    $black: #11111C;
  
    $primary_font: 'Roboto',
    sans-serif;
  
    .content_title {
      margin-bottom: 40px;
  
      .title {
        font-family: $primary_font;
        color: white;
  
        small {
          font-weight: 100;
          color: $secondary;
        }
      }
    }
  
    .main {
      padding: 0px 0px 70px 0px;
      width: 100%;
      margin: auto;
  
      * {
        transition: all .4s;
      }
  
      .top_banner,
      .bottom_banner {
        text-align: center;
        color: white;
  
        a {
          color: $secondary;
        }
      }
  
  
      .main_content {
        margin: 0px 0 0px;
        column-gap: 0px!important;
        width: 100%;
  
  
        .picture_wrapper {
          &.selector_image {
            // border: 4px solid rgba(0, 0, 0, 0);
            opacity: 0.5;
            &:hover {
              opacity: 0.8;
            }
          }
          &.new_image {
            -webkit-box-shadow:inset 0px 0px 0px 10px #f00;
    -moz-box-shadow:inset 0px 0px 0px 10px #f00;
    box-shadow:inset 0px 0px 0px 10px #f00;
            // border: 4px solid rgb(125, 235, 0);
            opacity: 1!important;
          }
          &.old_image {
            -webkit-box-shadow:inset 0px 0px 0px 10px #f00;
    -moz-box-shadow:inset 0px 0px 0px 10px #f00;
    box-shadow:inset 0px 0px 0px 10px #f00;
            // border: 4px solid rgb(0, 129, 235);
            opacity: 1!important;
          }
          cursor: pointer;
          margin-bottom: 1px;
          position: relative;
  
          .imageWrapper {
            width: 100%;
  
            img {
              height: 100%;
              width: 100%;
              object-fit: cover;
              border-radius: 2px;
            }
          }
  
  
          .picture_options {
            position: absolute;
            top: 0;
            left: 0;
            opacity: 1;
            height: 100%;
            width: 100%;
            background-color: rgba(22, 22, 22, 0.712);
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
  
            .fullscreenBtn {
              background-color: rgba(179, 164, 164, 0);
              color: white;
              font-size: 3rem;
              cursor: pointer;
              margin-bottom: 1rem;
              outline: none;
              border: none;
            }
  
            .otherActions {
              display: flex;
              justify-content: center;
              align-items: center;
  
              .gridAction {
                background-color: rgba(179, 164, 164, 0);
                color: white;
                outline: none;
                width: 40px;
                height: 40px;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 1.2rem;
                cursor: pointer;
                border: none;
  
                &:hover {
                  width: 40px;
                  height: 40px;
                  font-size: 2rem;
                }
              }
            }
          }
  
          &:hover {
            .picture_options {
              opacity: 1;
            }
          }
        }
      }
    }
  
  
    /* responsive */
    @media (min-width: 1200px) {
      .main_content {
        column-count: 4;
      }
    }
  
    @media (min-width: 768px) and (max-width: 1199px) {
      .main_content {
        column-count: 3;
      }
    }
  
    @media (min-width: 576px) and (max-width: 767px) {
      .main_content {
        column-count: 2;
      }
    }
  
    @media (max-width: 575px) {
      .main_content {
        column-count: 1;
      }
    }
  
  
  }
  
  
  
}