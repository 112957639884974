html,
body {
  height: auto;
  font-size: 14px;
  box-sizing: border-box;

  a {
    color: $primary;

    &:hover {
      color: $primary-dark;
    }

    &:focus {
      outline: 1px dotted rgba(0,0,0,0.2);
    }
  }
}

.ant-layout {
  background-color: transparent;
}

h1 {
  &.ant-typography {
    line-height: 1.1;
    font-size: 2.2rem;

    @media (max-width: 479px) {
      font-size: 2rem;
    }  
  }
}

.ant-layout-content {

  // .search-page-content {
  //   overflow-y: hidden;
  // }
  &.main-content {
    overflow: hidden;
    overflow-y: auto;
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch;
    height: 100vh;
    transition: margin-left ease-in-out 0.3s;
    margin-left: 0;
    z-index: 0;
    position: relative;
    padding-top: $nav-header-height;
    background-color: $lightest;

    @media (min-width: 768px) {
      margin-left: $nav-width;
    }
  }
}

.main-content-pads {
  @media (min-width: 768px) {
    padding: $nav-padding;
  }

  @media (max-width: 767px) {
    padding: $nav-padding-sm;
  }
}
