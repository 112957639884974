.productItemViewerWrapper {
  $small: 450px;
  $medium: 768px;

  * {
    transition: 0.3s !important;
  }

  width: 100%;

  .navbar {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 50px 0px 50px 70px;

    @media screen and (max-width: $medium) {
      padding: 50px 0px 50px 30px;
    }

    .navBackBtn {
      background-color: white;
      outline: none;
      border: none;
      cursor: pointer;
      font-size: 1.3rem;
    }

    h4 {
      padding-top: 5px;
      padding-left: 15px;
      font-size: 1.4rem;
    }
  }

  .itemViewer {
    padding: 0px 97px 0px 97px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;

    @media screen and (max-width: $medium) {
      padding: 0px 37px 0px 37px;
    }

    h2 {
      font-weight: 600;
      font-size: 1.8rem;
    }

    .itemPrice {
      font-size: 1.3rem;
      color: rgb(0, 85, 243);
      margin: 15px 0 18px 0;
      padding-left: 5px;
    }

    .itemCountWrapper {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      margin-left: -10px;
      margin-top: 2rem;

      .itemCountModifier {

        .decBtn,
        .incBtn {
          border: none;
          background-color: white;
          color: black;
          font-weight: 600;
          width: 30px;
          cursor: pointer;
          font-size: 1.5rem;
        }

        .itemCountInput {
          width: 50px;
          text-align: center;
          padding: 9px 0;
          border: 1px solid rgb(78, 131, 255);
          outline: none;
          border-radius: 5px;
          background-color: rgb(207, 220, 255);
        }
      }

      .addToCart {
        margin-left: 18px;
        padding: 10px 40px;
        border: none;
        background-color: rgb(0, 85, 243);
        border-radius: 4px;
        box-shadow: 2px 2px 35px rgba(0, 0, 0, 0.1);
        cursor: pointer;
        color: white;
        font-size: 0.9rem;

        &:hover {
          background-color: rgb(1, 73, 207);
          box-shadow: 2px 2px 35px rgba(0, 0, 0, 0.061);
        }
      }
    }
  }

  .itemDataViewer {
    padding: 65px 97px 0px 97px;

    @media screen and (max-width: $medium) {
      padding: 65px 37px 0px 37px;
    }

    .tabHeaders {
      margin-left: -15px;
      display: flex;
      justify-content: flex-start;
      align-items: center;

      .tabHeader {
        padding: 0 19px 0px 19px;
        font-weight: 600;
        cursor: pointer;

        p {
          padding-bottom: 8px;
          border-bottom: 4px solid rgba(61, 61, 255, 0);
        }

        &.active {
          p {
            border-bottom: 4px solid rgb(61, 61, 255);
          }
        }

        &:hover {
          p {
            border-bottom: 4px solid rgba(61, 61, 255, 0.431);
          }
        }
      }
    }

    .tabContent {
      margin-top: 1.4rem;
      font-size: 0.9rem;
    }
  }
}