.masonryGridWrapper {
  * {
    transition: 0.1s !important;
  }

  //variables
  $primary: #5408FF;
  $secondary: #FF08FF;
  $black: #11111C;

  $primary_font: 'Roboto',
  sans-serif;

  .content_title {
    margin-bottom: 40px;

    .title {
      font-family: $primary_font;
      color: white;

      small {
        font-weight: 100;
        color: $secondary;
      }
    }
  }

  .main {
    padding: 0px 20px 70px 20px;
    width: 100%;
    margin: auto;

    * {
      transition: all .4s;
    }

    .top_banner,
    .bottom_banner {
      text-align: center;
      color: white;

      a {
        color: $secondary;
      }
    }


    .main_content {
      margin: 0px 0 0px;
      column-gap: 1px;
      width: 100%;


      .picture_wrapper {
        cursor: pointer;
        margin-bottom: 1px;
        position: relative;

        .imageWrapper {
          width: 100%;

          img {
            height: 100%;
            width: 100%;
            object-fit: cover;
            border-radius: 2px;
          }
        }


        .picture_options {
          position: absolute;
          top: 0;
          left: 0;
          opacity: 1;
          height: 100%;
          width: 100%;
          background-color: rgba(22, 22, 22, 0.712);
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;

          .fullscreenBtn {
            background-color: rgba(179, 164, 164, 0);
            color: white;
            font-size: 3rem;
            cursor: pointer;
            margin-bottom: 1rem;
            outline: none;
            border: none;
          }

          .otherActions {
            display: flex;
            justify-content: center;
            align-items: center;

            .gridAction {
              background-color: rgba(179, 164, 164, 0);
              color: white;
              outline: none;
              width: 40px;
              height: 40px;
              display: flex;
              justify-content: center;
              align-items: center;
              font-size: 1.2rem;
              cursor: pointer;
              border: none;

              &:hover {
                width: 40px;
                height: 40px;
                font-size: 2rem;
              }
            }
          }
        }

        &:hover {
          .picture_options {
            opacity: 1;
          }
        }
      }
    }
  }


  /* responsive */
  @media (min-width: 1200px) {
    .main_content {
      column-count: 4;
    }
  }

  @media (min-width: 768px) and (max-width: 1199px) {
    .main_content {
      column-count: 3;
    }
  }

  @media (min-width: 576px) and (max-width: 767px) {
    .main_content {
      column-count: 2;
    }
  }

  @media (max-width: 575px) {
    .main_content {
      column-count: 1;
    }
  }


}



.loaderWrapper {
  width: 100%;
  height: 40vh;
  display: flex;
  justify-content: center;
  align-items: center;

  .loader,
  .loader:after {
    border-radius: 50%;
    width: 10em;
    height: 10em;
  }

  .loader {
    margin: auto;
    font-size: 3px;
    position: relative;
    text-indent: -9999em;
    border-top: 1.1em solid rgba(69, 69, 69, 0.2);
    border-right: 1.1em solid rgba(69, 69, 69, 0.2);
    border-bottom: 1.1em solid rgba(69, 69, 69, 0.2);
    border-left: 1.1em solid #454545;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: load8 1.1s infinite linear;
    animation: load8 1.1s infinite linear;
  }

  @-webkit-keyframes load8 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }

    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }

  @keyframes load8 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }

    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }

}