/* responsive */
.call-client-row {
  position: relative;
}
@media (min-width: 1701px) {
  .call-client-row {
    right: 30px;
  }
}
@media (min-width: 1124px) and (max-width: 1700px) {
  .call-client-row {
    left: 3px;
  }
}
@media (min-width: 768px) and (max-width: 1123px) {
  .call-client-row {
    left: 20px;
  }
}

@media (min-width: 481px) and (max-width: 767px) {
  .call-client-row {
    left: 8px;
  }
}

@media (max-width: 480px) {
  .call-client-row {
    left: 18px;
  }
}
