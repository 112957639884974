button {
  outline: none;
}

.cartSummaryViewerOuterWrapper {
  $small: 450px;
  $medium: 768px;
  height: 100%;

  .noItems {
    height: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .labelWrapper {
      display: flex;
      justify-content: center;
      align-items: center;

      .noItemHeader {
        padding-top: 30px;
        padding-right: 20px;
        font-size: 1rem;
        font-weight: 600;
      }

      .noItemImg {
        width: 70px;
      }

      margin-top: 1rem;
    }

    button {
      margin-top: 2.3rem;
      border: none;
      font-weight: 600;
      background-color: rgb(0, 68, 255);
      color: white;
      padding: 8px 65px 6px 65px;
      font-size: 0.8rem;
      cursor: pointer;
      box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.15);
      text-align: center;
      border-radius: 4px;

      span {
        font-size: 1rem;
      }

      &:hover {
        background-color: rgb(0, 57, 212);
      }
    }
  }

  .cartSummaryViewerInnerWrapper {
    padding: 4rem 3rem 1rem 3rem;

    @media screen and (max-width: $medium) {
      padding: 4rem 1rem 1rem 1rem;
    }

    .errorText {
      background-color: rgb(228, 0, 0);
      color: white;
      padding: 3px 35px;
      width: 100%;
      font-weight: 600;
      // border-radius: 5px;
      text-align: center;
      z-index: 0;
      position: absolute;
      top: 0;
      left: 0;
    }

    .checkoutBtn {
      border: none;
      background-color: rgb(0, 190, 0);
      color: white;
      border-radius: 4px;
      padding: 8px 35px;
      cursor: pointer;
      box-shadow: 2px 2px 35px rgba(0, 0, 0, 0.1);
      font-size: 0.9rem;
      font-weight: 600;

      &:hover {
        background-color: rgb(0, 160, 0);
        box-shadow: 2px 2px 35px rgba(0, 0, 0, 0.061);
      }
    }

    .cartHeader {
      display: flex;
      justify-content: space-between;
      align-items: center;

      @media screen and (max-width: $medium) {
        flex-direction: column;
      }

      h1 {
        font-weight: 600;
        flex: 1;
        text-align: left;
      }

      .headerCheckoutWrapper {
        flex: 1;
        display: flex;
        justify-content: flex-end;
        align-items: center;

        .subtotalLabel {
          padding-top: 15px;
          padding-right: 10px;
          font-size: 0.9rem;

          span {
            color: rgb(0, 108, 250);
            font-weight: 600;
          }
        }
      }
    }


    .productCell {
      flex: 12;
    }

    .priceCell {
      flex: 2;
    }

    .quantityCell {
      flex: 4;
    }

    .subtotalCell {
      flex: 2;
    }

    .cartItemsHeader {
      width: 100%;
      font-size: 0.8rem;
      font-weight: 600;
      display: flex;
      margin-top: 22px;
      align-items: center;
      background-color: rgba(233, 233, 233, 0.685);
      @media screen and (max-width: $medium) {
        display: none!important;
      }

      .headerCell {
        padding: 7px 10px;

        &.priceCell {
          padding-left: 30px;
        }

        &.quantityCell {
          padding-left: 30px;
        }

        &.subtotalCell {
          padding-left: 10px;
        }
      }
    }

    .cartContent {

      height: 71vh;
      overflow-y: scroll;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-direction: column;
      width: 100%;
      margin-top: 1rem;

      .cartItemsWrapper {
        width: 100%;

        .productCell {
          flex: 12;
        }

        .priceCell {
          flex: 2;
        }

        .quantityCell {
          flex: 4;
        }

        .subtotalCell {
          flex: 2;
        }

        .cartItemsHeader {
          width: 100%;
          font-size: 0.8rem;
          font-weight: 600;
          display: flex;
          align-items: center;
          background-color: rgba(233, 233, 233, 0.685);

          .headerCell {
            padding: 7px 10px;

            &.priceCell {
              padding-left: 30px;
            }

            &.quantityCell {
              padding-left: 30px;
            }

            &.subtotalCell {
              padding-left: 10px;
            }
          }
        }

        .cartsItemsBody {
          width: 100%;

          .errorMessage {
            display: block;
            width: 100%;
            background-color: rgb(228, 0, 0);
            padding: 4px 35px;
            margin-top: -5px;
            font-size: 0.8rem;
            color: white;
            font-weight: 600;
            border-bottom-left-radius: 6px;
            border-bottom-right-radius: 6px;
            margin-bottom: 8px;
          }

          .cartItemRowLarge {
            @media screen and (max-width: $medium) {
              display: none!important;
            }
            margin: 5px 0;
            padding: 8px 0;
            border-radius: 6px;
            position: relative;
            width: 100%;
            width: 100%;
            font-size: 0.9rem;
            display: flex;
            align-items: center;
            border: 2px solid rgb(214, 214, 214);
            background-color: rgb(255, 255, 255);

            &:hover {
              background-color: rgba(197, 227, 255, 0.287);
              border: 2px solid rgba(197, 227, 255, 0.287);
            }

            &.error {
              border-bottom-left-radius: 0px;
              border-bottom-right-radius: 0px;
              border: 2px solid rgb(228, 0, 0) !important;
            }

            .cartItemCell {
              padding: 7px 10px;

              &.productCell {
                .itemTitle {
                  font-weight: 600;

                  span {
                    font-weight: 400;
                  }
                }

                .itemPreviewer {
                  display: flex;
                  align-items: center;

                  .thumbnailViewer {
                    img {
                      width: 100px;
                    }
                  }

                  .imageActions {
                    padding-left: 5px;
                    padding-top: 8px;

                    .imageAction {
                      margin-bottom: 3px;
                      cursor: pointer;

                      span {
                        padding-left: 5px;
                        text-decoration: underline;
                      }

                      &:hover {
                        color: rgb(0, 79, 226);
                      }

                      &.removeBtn {
                        font-size: 0.7rem;
                        color: red;

                        span {
                          text-decoration: none !important;
                        }
                      }
                    }
                  }
                }
              }

              &.priceCell {}

              &.quantityCell {

                .decBtn,
                .incBtn {
                  border: none;
                  background-color: rgba(255, 255, 255, 0);
                  color: black;
                  font-weight: 600;
                  width: 20px;
                  cursor: pointer;
                  font-size: 1.5rem;
                }

                .itemCountInput {
                  width: 40px;
                  text-align: center;
                  padding: 9px 0;
                  border: 1px solid rgb(78, 131, 255);
                  outline: none;
                  border-radius: 5px;
                  background-color: rgb(207, 220, 255);
                }
              }

              &.subtotalCell {
                font-weight: 600;
              }
            }
          }

          .cartItemRowMobile {
            display: none;
            @media screen and (max-width: $medium) {
              display: flex!important;
            }
            flex-direction: column;
            justify-content: center;
            align-items: center;
            border-radius: 6px;
            margin-top: 15px;
            border: 2px solid rgb(214, 214, 214);
            background-color: rgb(255, 255, 255);
            &:hover {
              background-color: rgba(197, 227, 255, 0.287);
              border: 2px solid rgba(197, 227, 255, 0.287);
            }

            &.error {
              border-bottom-left-radius: 0px;
              border-bottom-right-radius: 0px;
              border: 2px solid rgb(228, 0, 0) !important;
            }
            .itemRow {
              padding-top: 0.5rem;
              padding-bottom: 0.5rem;
              width: 90%;
              display: flex;
              justify-content: center;
              align-items: flex-start;
              .label {
                flex: 4;
                text-align: left;
                font-weight: 600;
              }
              .value {
                flex: 5;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: center;
                .itemTitle {
                  font-weight: 600;
                  margin-bottom: 2px;
                }
                .fileName {
                  font-size: 0.9rem;
                  margin-bottom: 20px;
                }
                .thumbnailViewer {
                  img {
                    width: 100px;
                  }
                }
                .imageActions {
                  padding-left: 0px;
                  padding-top: 8px;

                  .imageAction {
                    margin-bottom: 3px;
                    cursor: pointer;

                    span {
                      padding-left: 5px;
                      text-decoration: underline;
                    }

                    &:hover {
                      color: rgb(0, 79, 226);
                    }

                    &.removeBtn {
                      font-size: 0.7rem;
                      color: red;

                      span {
                        text-decoration: none !important;
                      }
                    }
                  }
                }
                .itemCountModifier {
                  .decBtn,
                .incBtn {
                  border: none;
                  background-color: rgba(255, 255, 255, 0);
                  color: black;
                  font-weight: 600;
                  width: 20px;
                  cursor: pointer;
                  font-size: 1.5rem;
                }

                .itemCountInput {
                  width: 40px;
                  text-align: center;
                  padding: 9px 0;
                  border: 1px solid rgb(78, 131, 255);
                  outline: none;
                  border-radius: 5px;
                  background-color: rgb(207, 220, 255);
                }
                }
              }
            }

          }
        }
      }

      .promoCodeWrapper {
        margin-top: 18px;
        padding-left: 3%;
        @media screen and (max-width: $medium) {
          margin-top: 28px;
          padding-left: 2%;
        }
        .promoError {
          font-size: 0.8rem;
          font-weight: 600;
          margin-top: 5px;
          color: red;
        }  
        .enterPromo {
          .promoLabel {
            font-size: 0.8rem;
            font-weight: 600;
          }
          
          .promoBox {
            position: relative;
            width: 170px;
            height: 30px;


  
            input {
              width: 170px;
              height: 30px;
              border: 1px solid rgb(78, 131, 255);
              outline: none;
              border-radius: 5px;
              background-color: rgb(207, 220, 255);
              padding: 9px 45px 9px 15px;
  
            }
  
            .applyBtn {
              position: absolute;
              right: 5px;
              background-color: rgba(255, 255, 255, 0);
              border: none;
              color: rgb(2, 58, 212);
              font-weight: 600;
              cursor: pointer;
              font-size: 0.7rem;
              top: 6px;
            }
  
            .closeBtn {
              position: absolute;
              right: -22px;
              background-color: rgba(255, 255, 255, 0);
              border: none;
              color: red;
              font-size: 0.7rem;
              top: 6px;
            }
          }
        }
        .promoApplied {
          margin-top: 1rem;
          width: 80%;
          .promoCodes {
            display: flex;
            // height: 25px;
            font-size: 0.8rem;
            font-weight: 600;
            flex-wrap: wrap;
            margin-bottom: 15px;
            .promoLabel {
              margin-top: 5px;
            }
            .promoCode {
              margin: 5px 5px 5px 0px;
              font-size: 0.7rem;
              font-weight: 600;
              background-color: rgb(0, 117, 195);
              color: white;
              padding: 4px 5px 4px 15px;
              border-radius: 60px;
              .closeBtn {
                cursor: pointer;
                margin-left: 5px;
                position: unset;
                background-color: rgba(255, 255, 255, 0);
                border: none;
                color: rgb(251, 249, 249);
                font-size: 0.7rem;
              }
            }
          }
        }
      }

      .totalWrapper {
        margin-top: -1rem;
        margin-bottom: 2rem;
        padding-left: 45%;
        @media screen and (max-width: $medium) {
          padding-left: 1%;
        }
        padding-right: 4%;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .label {
          font-weight: 600;
          font-size: 1.5rem;
        }

        .amount {
          font-weight: 600;
          font-size: 1.5rem;
          color: rgb(0, 79, 226);
        }
      }

      .discountWrapper {
        margin-top: 2rem;
        margin-bottom: 0rem;
        padding-left: 45%;
        @media screen and (max-width: $medium) {
          margin-top: 5rem;
          padding-left: 0%;
        }
        padding-right: 4%;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .label {
          padding-left: 4px;
          font-weight: 600;
          font-size: 1.1rem;
        }

        .amount {
          font-weight: 600;
          font-size: 1.1rem;
          color: rgb(30, 197, 0);
        }
      }
    }
  }

  .cartFooter {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .backToShoppingBtn {
      background-color: white;
      border: none;
      cursor: pointer;

      span {
        text-decoration: underline;
      }
    }
  }
}