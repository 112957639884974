@import "components/design/_variables";

.ant-layout-content {
  .ant-list-item-check {
    padding: 1rem $nav-padding;
    float: left;

    display: flex;
    align-items: center;

    border-bottom: 1px solid $lighter;
    border-right: 1px solid $lighter;

    @media (max-width: 479px) {
      padding: 1.77rem $nav-padding-sm;
    }


  }

  .ant-list-item {
    cursor: pointer;
  }

}

.task-page {
  .ant-select {
    width: 100%;
  }
}

.taskListItem {
  border: 0;
  box-shadow: 0 0 4px 0 rgb(0 0 0 / 10%);
  margin-bottom: 15px;
  background-color: #F1F2F4; 
  border-radius: 10px;
  padding: 10px;

  &__Content {
    padding-right: 15px;
  }

  &__Action {
    display: flex !important;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    @media(max-width: 767px) {
      padding-top: 20px;
    }

    .ant-select {
      width: auto !important;
      margin: 0;
      min-width: 125px;
      margin-left: auto;

      .ant-select-selector {
        height: 39px !important;
        border-radius: 5px !important;
        background: rgb(99,73,117) !important;
        color: white !important;
        border: 0 !important;
        font-size: 14px !important;
      }
    }

    .ant-list-item-check {
      margin-left: 10px;
      background: #526171;
      color: white !important;
      border-radius: 5px;
      padding: 8px 10px;
      width: auto;
      text-align: center !important;
      font-size: 14px;

      &.completed {
        margin-left: auto;
        background: rgb(255, 179, 28);
        color: #ffffff !important;
        justify-content: center;
      }

      .ant-checkbox {
        opacity: 0;
        width: 0;
      }
    }
  }

  &__Date {
    background: rgb(82,97,113);
    border-radius: 5px;
    display: flex !important;
    height: 80px;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0 10px 10px;
    width: 100px;

    &--Day {
      font-size: 25px;
      color: #ffffff !important;
      font-weight: 700;
      line-height: 25px;
    }

    &--MonthYear {
      text-transform: uppercase;
      font-weight: 500;
      color: #fff !important;
    }

    &--Due {
      position: absolute;
      bottom: 0;
      font-size: 10px;
      text-transform: uppercase;
      padding: 0 5px;
      height: 20px;
      line-height: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: rgb(255, 179, 28);
      border-color: white;
      color: white !important;
      margin-bottom: -5px;
      border-radius: 2px;
    }
  }

  &__Data {
    padding: 5px 10px;
    width: calc(100% - 100px);

    &--Title {
      font-size: 15px;
      font-weight: 500;
    }

    &--Status {
      padding-top: 5px;

      .taskStatus {
        &.red {
          mark {
            background: rgb(142, 59, 70);
            color: white;
          }
        }

        mark {
          margin-right: 10px;
        }
      }
    }
  }
}

.taskFilters {
  .ant-card-body {
    padding: 15px 0 30px !important;
    margin: 0 -10px;
    display: flex;
  }

  .ant-typography {
    cursor: pointer;
    padding: 5px 30px;
    margin: 0 10px;
    font-size: 14px;
    background: #92b0b6;
    border-radius: 10px;
    box-shadow: 0 0 3px 0 rgb(0 39 102 / 40%);
    color: white !important;
    transition: 0.3s ease;

    &:hover,
    &.selected {
      color: #ffffff !important;
      background: #526171;
    }
  }
}