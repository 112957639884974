.search-container {
  .search-listing {
    background-color: #F1F2F4;
  }
  input.ant-input {
    background-color: #F1F2F4;
  }
  .ant-select {
    .ant-select-selector {
      background-color: #F1F2F4 !important;

      .ant-select-selection-search {
        background-color: #F1F2F4;

      }
    }
  }
}
.search-invoice-container { 
  background-color: #fff;

  .ant-btn,
  .ant-select-selector {
    border: 0;
  }

  .ant-input-affix-wrapper,
  .ant-input-group-addon {
    border: 0;
    background-color: transparent;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    border-radius: 0;
  }

  .ant-input-group-addon:first-child {
    background-color: #f0f2f5;
  }
}
