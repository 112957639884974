.Calls {
    padding: 7.5px;
    background: #F1F2F4;
    box-shadow: 0 0 4px 0 rgb(0 0 0 / 10%);
    border-radius: 10px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;


    &__Icon {
        width: 50px;
        height: 50px;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 22px;
        background: rgb(146, 176, 182);
        color: white;
    }

    &__Content {
        width: calc(100% - 50px);
        padding: 0px 0 0 10px !important;

        &--Head {

            h4.ant-typography {
                font-size: 17px;
                font-weight: 700;
                margin: 0;
                display: flex;
                align-items: center;

                .ant-tag {
                    font-size: 10px;
                    font-weight: 500;
                    padding: 3px 5px;
                    line-height: 10px;
                    margin-left: 10px;
                    border: 0;

                    &.text {
                        color: #fff;
                        background: #13c2c2;
                    }

                    &.email {
                        color: #fff;
                        background: #1890ff;
                    }
                }
            }

            .Calls__Date {
                font-size: 12px;
                font-weight: 500;
                padding: 5px;
                line-height: 12px;
                border-radius: 5px;
                background: rgb(255,229,143);
                color: #002766;

                .anticon {
                    margin-right: 5px;
                }
            }
        }
    }

    &__Description {
        display: flex;
        margin: 0;
        font-size: 14px;
        line-height: 24px;
        color: #8c8c8c !important;
    }
    .ant-checkbox-wrapper {
        padding: 0 !important;
        box-shadow: none !important;
        border: 0 !important;

        .ant-checkbox {
            display: none;
            &+span {
                margin-left: 10px;
      background: #526171;
      color: white !important;
      border-radius: 5px;
      padding: 8px 10px;
      width: auto;
      text-align: center !important;
      font-size: 14px;
            }
            &.ant-checkbox-checked+span {
                background: rgb(255, 179, 28);
                color: #fff;

                
            }
           
        }

    }
}

.CallsGrid__Item {
    padding: 7.5px;
}