$small: 450px;
$medium: 768px;

.session-drawer-wrapper {
  .sessionMetaDataWrapper {
    margin-top: -9px;
    margin-bottom: -29px;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .sessionMetaIcon {
      img {
        width: 60px;
      }
    }

    .sessionMetaData {
      display: flex;
      padding-top: 10px;
      justify-content: flex-start;
      flex-direction: column;
      width: 75%;

      .sessionTitle {
        font-size: 1.5rem !important;
      }

      .sessionDate {
        font-size: 0.8rem !important;
      }
    }
  }

  /*
 CSS for the main interaction
*/
  .tabset>input[type="radio"] {
    position: absolute;
    left: -200vw;
  }

  .tabset .tab-panel {
    display: none;
  }

  .tabset>input:first-child:checked~.tab-panels>.tab-panel:first-child,
  .tabset>input:nth-child(3):checked~.tab-panels>.tab-panel:nth-child(2),
  .tabset>input:nth-child(5):checked~.tab-panels>.tab-panel:nth-child(3),
  .tabset>input:nth-child(7):checked~.tab-panels>.tab-panel:nth-child(4),
  .tabset>input:nth-child(9):checked~.tab-panels>.tab-panel:nth-child(5),
  .tabset>input:nth-child(11):checked~.tab-panels>.tab-panel:nth-child(6) {
    display: block;
  }

  .tabset>label {
    position: relative;
    display: inline-block;
    padding: 8px 4vw 15px 15px;
    border: 1px solid transparent;
    border-bottom: 0;
    cursor: pointer;
    font-weight: 600;

    @media screen and (max-width: $medium) {
      padding: 15px 15vw 25px 15px;
    }
  }

  .tabset>label::after {
    content: "";
    position: absolute;
    left: 15px;
    bottom: 10px;
    width: 42px;
    height: 4px;
    background: #8d8d8d;
  }

  .tabset>label:hover,
  .tabset>input:focus+label {
    color: #06c;
  }

  .tabset>label:hover::after,
  .tabset>input:focus+label::after,
  .tabset>input:checked+label::after {
    background: #06c;
  }

  .tabset>input:checked+label {
    border-color: #ccc;
    border-bottom: 1px solid #fff;
    margin-bottom: -1px;
    background-color: white;
  }

  .tab-panel {
    padding: 30px 0;
    border-top: 1px solid #ccc;
  }

  .tabset {
    width: 65vw;
    margin-bottom: -0.75rem;
    margin-top: 1rem;

    @media screen and (max-width: $medium) {
      width: 85vw;
    }
  }
}

.ant-drawer-body {
  overflow-x: hidden;
}

.tab-panel-wrapper {
  padding: 30px 40px;

  @media screen and (max-width: $medium) {
    padding: 30px 10px;
  }
}

.ant-btn.printConfirmationBtn {
  display: flex;
  padding: 0 10px;
  align-items: center;
  color: #ffffff;
  font-weight: 600;
  height: 40px;

  .anticon.anticon-printer {
    opacity: 1 !important;
    margin-bottom: -2px;
  }
}