@import "components/design/_variables";

.events {
  list-style: none;
  margin: 0;
  padding: 0;

  @media (max-width: 767px) {
    text-align: center;
  }

  .calendar-week-list & {
    width: 100%;
    min-height: 20px;
    overflow: hidden;
  }

  > li {
    line-height: 0;
    margin-left: 0.125rem;

    .calendar-week-list & {
      display: inline-block;
      margin-left: 0;

      &:not(:last-child) {
        margin-right: 0.2rem;
      }
    }
  }

  .ant-badge-status {
    overflow: hidden;
    white-space: nowrap;
    line-height: 1;
    width: 100%;
    display: inline-block;
    text-overflow: ellipsis;

    .ant-badge-status-dot {
      top: 0;
      width: 5px;
      height: 5px;
    }

    .ant-badge-status-text {
      font-size: 0.75rem;
      margin-left: 0.375rem;

      @media (max-width: 767px) {
        display: none;
      }

      .calendar-week-list & {
        display: inline-block;
      }
      .calendar-day-view & {
        display: none;
      }
    }
  }
}
