.list-inline {
  list-style: none;
  padding: 0;
  margin: 0;

  > li {
    display: inline-block;

    &:not(:last-child) {
      margin-right: 0.5rem;

      @media (min-width: 480px) {
        margin-right: 1rem;        
      }
    }
  }
}

.list-meta-float {
  .ant-list-item-meta-content {
    @media (min-width: 480px) {
      display: flex;
    }

    .ant-list-item-meta-title,
    .ant-list-item-meta-description {
      @media (min-width: 480px) {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    .ant-list-item-meta-title {
      @media (min-width: 480px) {
        flex: 0 0 33.33%;
        max-width: 33.33%;
      }
    }

    .ant-list-item-meta-description {
      @media (min-width: 480px) {
        flex: 0 0 66.66%;
        max-width: 66.66%;
        text-align: right;
        padding-left: 2rem;
        margin-left: auto;
      }
    }
  }
}
