.cartDrawerContentWrapper {
  $small: 450px;
  $medium: 768px;
  height: 100vh;
  // width: 60vw;
  overflow-x: hidden;
  position: relative;
  @media screen and (max-width: $medium) {
    overflow-y: hidden;
  }
  .closeBtn {
    position: absolute;
    top: 30px;
    right: 35px;
    background-color: white;
    border: none;
    outline: none;
    cursor: pointer;
    color: black;
    font-size: 1rem;
  }
}