.reportProperties {

    .ant-pro-card-body {
        padding: 10px 0;
        margin-bottom: 50px;
    }

    &__Action {
        padding-left: 2rem;

        @media (max-width: 993px) {
            padding-left: 0;
        }

        &--Card {
            height: 100%;
            box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1);
            padding: 5px !important;

            &__Buttons {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                margin-bottom: 50px;
                align-items: flex-start;

                .ant-btn {
                    width: 100%;
                    margin: 0 0 20px;
                    background: #BAE7FF;
                    color: #000 !important;
                    font-weight: 500;
                    border: 0;
                    line-height: 14px;
                    min-height: 35px;
                    transition: 0.3s ease;
                    white-space: pre-wrap;
                    height: auto;
                    transition: 0.3s ease;

                    @media (max-width: 1366px) {
                        font-size: 12px !important;
                    }

                    &:hover {
                        background: #91d5ff;
                    }
                }
            }
        }
    }

    &--Card {
        padding: 0px 5px !important;
    }
}

.reportProperties .ant-row.form-group {
    padding: 0 10px !important;
    border: 0 !important;
}

.chartCard {
    overflow: hidden;
    height: 100%;
    background: #ffffff;

    .ant-space.pieChartSpace {
        justify-content: center;
        display: flex;

        &.singleChart {
            .ant-space-item {
                width: 100% !important;
            }
        }

        .ant-space-item {
            width: 50%;

            .chart1,
            .chart2 {
                background: #fbfbfb;

                .ant-card-head {
                    justify-content: flex-start;
                }

                .ant-card-head-title {
                    font-size: 12px;
                    padding: 10px 0 0;
                }
            }
        }
    }

    &Head {
        width: 100%;
        display: flex !important;
        justify-content: space-between;
        align-items: center !important;

        .ant-space-item {
            display: flex;

            &:nth-child(2) {
                width: calc(100% - 25px);
                padding-right: 10px;
                overflow: hidden;
            }

            .ant-btn {
                padding: 0;
                border: 0;
                width: 15px !important;
                height: 18px !important;
                color: rgb(93, 174, 255);
            }
        }
    }

    .ant-card-head {
        padding: 10px 15px;
        min-height: 0;

        .ant-card-head-title {
            padding: 0;
            display: flex;

            h5 {
                line-height: 14px;
                font-size: 13px;
                font-weight: 500;
                margin: 0;
            }
        }
    }

    .ant-card-body {
        padding: 10px;
    }
}

.viewReportChart {
    justify-content: center;
    padding-bottom: 20px;
    margin-bottom: 50px;
    border-bottom: 2px solid #f5f5f5;
}

.chartCardHead {
    .hideCheckbox {
        display: none !important;
    }
}

.chartGroupTabs {
    overflow: hidden;

    .ant-dropdown-trigger {
        position: absolute;
        right: 0;
        top: 0;
        z-index: 99;
        background: #ffffff;
    }

    .ant-tabs-nav {
        margin-bottom: 10px !important;
    }

    .ant-tabs-extra-content {
        .ant-btn {
            padding: 0 !important;
            width: auto;
            height: auto;
            border: 0;
            margin-left: 5px !important;
        }
    }

    .ant-card-body {
        padding: 5px !important;
    }

    .ant-tabs-tabpane {
        .ant-pro-card-body {
            padding: 0 !important;

            .ant-card {
                border: 0;
            }

            .ant-card-head {
                display: flex;
                border: 0;
                padding: 0;
                justify-content: flex-end;
                padding-bottom: 10px;

                .ant-btn {
                    width: auto;
                    height: auto;
                    border: 0;
                    color: #000000;
                    padding: 3px 5px;
                    border-radius: 30px;
                    font-size: 12px;
                    background: #cf1322;
                    color: #fff;
                    line-height: 12px;
                    font-weight: 500;
                }
            }
        }
    }
}

.GroupChartsBtn {
    display: flex;
    align-items: center;
    background: rgb(99, 73, 117) !important;
    border: 0 !important;

    &:hover,
    &:focus {
        background: #391085 !important;
    }
}

.RemoveGroupsBtn {
    display: flex;
    align-items: center;
    background: #b37feb !important;
    border: 0 !important;

    &:hover,
    &:focus {
        background: #722ed1 !important;
    }
}

.groupCheckBox {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 9;
    padding: 10px !important;
    background: #ffffff;
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.1);
}

.addMoreCharts {
    color: rgb(93, 174, 255) !important;
}

.saveDashboardBtn {
    display: flex;
    align-items: center;
    background-color: rgb(255, 179, 28) !important;
    color: white !important;
}


.reportList {
    max-width: 100%;
    width: 50%;
    padding: 0 0 40px 20px;

    label {
        font-weight: 700;
    }
}

.reportProperties .ant-row.form-group {
    margin-left: 0 !important;
}