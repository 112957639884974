@import "components/design/_variables";

//Medium Screen - 1200 screen size
@mixin md-sm-screen {
  @media only screen and (max-width: 1200px) {
    @content;
  }
}

//Medium Screen - 991 screen size
@mixin sm-screen {
  @media only screen and (max-width: 991px) {
    @content;
  }
}

//Medium Screen - 770 screen size
@mixin xs-screen {
  @media only screen and (max-width: 767px) {
    @content;
  }
}

.quick-access-page {
  .ant-list-split .ant-list-item:last-child {
    border-bottom: 1px solid #f0f0f0;
  }

  .client-details-list {
    .ant-list-item-meta-avatar {
      font-size: 30px;
      color: #002766;
    }

    .ant-pro-card-header {
      background-color: #002766;
      color: $white-primary;
    }
  }

  .ant-btn-secondary {
    color: #40a9ff;
    border-color: #40a9ff;
    background: $white-primary;
  }

  .ant-btn.ant-btn-block {
    height: 100%;
  }

  .quick-access-content {
    .ant-tabs-tabpane {

      // margin: 0px 10px;
      .ant-pro-card {
        .ant-pro-card-body {
          padding: 0;
        }
      }
    }
  }

  .ProCardBtn {
    .ant-btn.ant-btn-block {
      height: 100%;
      padding: 30px 15px;
      border-radius: 10px;

      .anticon {
        font-size: 35px;
        margin-bottom: 15px;
      }
    }
  }

  .tab-title {
    font-size: 20px;
    margin-right: 0;
  }

  .ant-tabs-tab {
    margin: 0 20px 0 10px;
    padding: 8px 20px;
  }

  .ant-tabs-card>.ant-tabs-nav .ant-tabs-tab-active {
    color: $white-primary;
    background: #c0d1cf;
  }

  .ant-tabs-card.ant-tabs-top>.ant-tabs-nav .ant-tabs-tab {
    // border-radius: 6px 20px 20px 6px;
    /* height: 60px; */
    padding: 20px 35px;
    // margin-right: 20px;
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: $white-primary;
  }

  .inputField {
    padding-top: 15px;
  }

  .search-container .ant-pro-card-body {
    padding-left: 5px;
    // padding-right: 2px;
  }

  .search-buttons>.ant-pro-card-body-center {
    // .ant-pro-card-body-center {
    padding-left: 8px;
    padding-right: 8px;
    // }
  }

  .sessions-list {
    .ant-list-item-meta-avatar {
      margin-right: 16px;
      // color: $white-primary;
      background: #cf1322;
      // padding: 5px;
      padding: 10px 1px 1px;
      border-radius: 4px;
      border: 1px solid #cf1322;
    }

    .ant-list-bordered .ant-list-item {
      padding-left: 0px;
    }

    .calendar-date {
      font-size: 15px;
      font-weight: bold;
    }

    .calendar-space {
      background-color: #f5f5f5;
      padding: 5px;
    }

    .list-item-content {
      width: 200px;
    }

    .ant-typography mark {
      padding: 5px 10px;
      background-color: #ffe58f;
      border-radius: 5px;
    }
  }

  .ant-btn-default-green {
    color: #389e0d;
    border-color: #389e0d;

    // background: #389e0d;
    &:hover,
    &:focus {
      border-color: #52c41a;
      color: #52c41a;
    }
  }

  .ant-btn-default-green-active {
    color: #389e0d;
    border-color: #389e0d;
    background: #d9f7be;

    &:hover,
    &:focus {
      background: #d9f7be;
      border-color: #389e0d;
      color: #389e0d;
    }

    &.disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }

  .ant-btn-default-blue {
    color: #096dd9;
    border-color: #096dd9;

    // background: #1d39c4;
    &:hover,
    &:focus {
      border-color: #1890ff;
      color: #1890ff;
    }
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #363636 !important; 
    font-weight: 500;
 }

  .ant-btn-default-orange {
    color: #d46b08;
    border-color: #d46b08;

    // background: #d46b08;
    &:hover,
    &:focus {
      border-color: #fa8c16;
      color: #fa8c16;
    }
  }

  .ant-btn-default-purple {
    color: #722ed1;
    border-color: #722ed1;

    // background: #d46b08;
    &:hover,
    &:focus {
      border-color: #531dab;
      color: #531dab;
    }
  }

  .ant-btn-default-red {
    color: #cf1322;
    border-color: #cf1322;

    // background: #d46b08;
    &:hover,
    &:focus {
      border-color: #f5222d;
      color: #f5222d;
    }

    &.disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }

  .ant-btn-default-red-active {
    color: #cf1322;
    border-color: #cf1322;
    background: #ffa39e;

    &:hover,
    &:focus {
      background: #ffa39e;
      border-color: #cf1322;
      color: #cf1322;
    }

    &.disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }

  .ant-btn-default-yellow {
    color: #fadb14;
    border-color: #fadb14;

    // background: #d46b08;
    &:hover,
    &:focus {
      border-color: #ffec3d;
      color: #ffec3d;
    }

    &.disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }

  .ant-btn-default-yellow-active {
    color: #fadb14;
    border-color: #fadb14;
    background: $white-primary;

    &:hover,
    &:focus {
      background: $white-primary;
      border-color: #fadb14;
      color: #fadb14;
    }

    &.disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }

  .multiFieldLeft {
    width: 60%;
    float: left;
  }

  .multiFieldRight {
    width: 40%;
  }

  .ant-input-borderless {
    width: 100%;
  }

  .ProCardBtn {
    position: relative;

    .ant-badge.ant-badge-not-a-wrapper {
      position: absolute;
      right: 0;
      top: 0;
      margin: 5px;

      .ant-badge-dot {
        width: 15px;
        height: 15px;
        position: relative !important;
        bottom: 0 !important;
        left: 0 !important;
        transform: none;
      }
    }
  }

  .ant-pro-card-split {
    .ant-pro-card-body {
      @media (max-width: 767px) {
        flex-direction: column;

        .ant-pro-card-col {
          width: 100% !important;
        }
      }
    }
  }
}

.ant-pro-card.quick-access-rightSide {
  height: 100%;
  background: $white-primary;

  .ant-pro-card-header {
    background: #526171;
    padding: 15px 15px;
    height: 70px !important;
    min-height: 70px !important;

    @include xs-screen() {
      height: 50px !important;
      min-height: 50px !important;
    }

    .ant-btn-primary {
      background: $white-primary;
      color: #096dd9;
      border-radius: 5px;
    }

    .ant-pro-card-title {
      font-size: 20px;
      font-weight: 600;
      line-height: 21px;
      color: $white-primary;
    }
  }
}

.search-section {
  &>.ant-pro-card-body {
    display: flex;
    flex-wrap: wrap;
  }

  &-left {
    width: calc(100% - 250px);

    @include xs-screen() {
      width: 100%;
    }
  }

  &-right {
    width: 250px;

    @include xs-screen() {
      width: 100%;
      padding: 0 15px 15px;

      .search-buttons {
        .ant-pro-card-body {
          padding: 0 !important;
        }
      }
    }
  }
}

.ant-row.quick-search-panel-block {
  margin: 0 -10px;

  .ant-col {
    padding: 0 10px;

    &:first-child {
      padding-right: 0;
    }

    &:last-child {
      padding-left: 0;
    }
  }

  .ant-select {
    min-width: 0 !important;
    width: 100%;

    .ant-select-selector {
      border-radius: 0;
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
      background: #f5f5f5;
      border: 0 !important;
      border: 0;
      font-size: 14px;
      line-height: 14px;
      outline: 0;
      box-shadow: none !important;

      .ant-select-selection-item {
        // font-weight: 300;
        padding: 0 5px;
        line-height: 40px !important;
      }
    }
  }

  .ant-input-affix-wrapper {
    border-color: #f1f1f1 !important;
    box-shadow: none !important;
    border-radius: 5px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    height: 40px;
    outline: 0;

    input {
      padding: 0 10px;
      // font-weight: 300;
      line-height: 40px;
      font-size: 15px;
    }
  }
}

.search-buttons {
  position: relative;

  .ant-pro-card-body.ant-pro-card-body-center {
    padding: 24px 0;
  }
}

.ant-pro-card.search-container .ant-pro-card-body {
  padding-left: 0;
}

.search-buttons-block {
  margin-left: auto;
  display: flex;
  justify-content: space-between;
  width: 100%;

  @include xs-screen() {
    justify-content: flex-end;
  }

  button {
    border-radius: 5px;
    margin-left: 0;
    width: calc(50% - 7px);
    height: 40px;

    @include xs-screen() {
      width: auto;
      margin-left: 15px;
    }

    &.ant-btn-secondary {
      color: #40a9ff;
      border-color: #40a9ff;
      background: $white-primary;
    }
  }

  &.icon-buttons {
    position: absolute;
    left: 20px;
    top: 14px;
    width: 90px;

    @media(max-width: 767px) {
      position: relative;
      width: 100%;
      left: 0;
      top: 0;
      justify-content: flex-start;
      margin-bottom: 10px;
    }

    button {
      border-radius: 50% !important;
      font-size: 14px;
      padding: 0 0 1px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;
      min-width: 40px;

      @media(max-width: 767px) {
        margin: 0 10px 0 0 !important;
        width: 30px;
        height: 30px;
        min-width: 30px;
      }
    }
  }
}

.quick-access-inner-head {
  h1 {
    font-size: 24px;
    margin: 0;
    line-height: 39px !important;
  }

  .ant-pro-card-body {
    padding: 10px 0 20px;
  }
}

.ProCardBtn {
  position: relative;

  .ant-badge.ant-badge-not-a-wrapper {
    position: absolute;
    right: 0;
    top: 0;
    margin: 5px;

    .ant-badge-dot {
      width: 15px;
      height: 15px;
      position: relative !important;
      bottom: 0 !important;
      left: 0 !important;
      transform: none;
    }
  }
}


.quickAccessList {
  display: flex;
  flex-wrap: wrap;
  padding: 20px;
  border-radius: 10px;
  background: #fafafa;
  box-shadow: 0 2px 4px 0 rgb(0 0 0 / 10%);
  width: 100%;

  &__Date {
    width: 85px;

    @media(max-width: 767px) {
      width: 100%;
      padding-bottom: 10px;
      display: flex;
      justify-content: center;
    }

    label {
      position: relative;
      display: flex;
      flex-direction: column;
      padding: 15px 0px 10px;
      background: rgb(82,97,113);
      border-radius: 10px;
      text-align: center;
      cursor: pointer;

      @media(max-width: 767px) {
        width: 100px;
      }

      // &:before {
      //   content: "";
      //   position: absolute;
      //   top: -6px;
      //   left: 12px;
      //   width: 4px;
      //   height: 15px;
      //   background: #69c0ff;
      //   border-radius: 4px;
      // }

      // &:after {
      //   content: "";
      //   position: absolute;
      //   top: -6px;
      //   right: 12px;
      //   width: 4px;
      //   height: 15px;
      //   background: #69c0ff;
      //   border-radius: 4px;
      // }
    }

    &--Icon {
      position: absolute;
      left: 0;
      top: 18px;
      width: 100%;
      height: 3px;
      background: #69c0ff;
    }

    &--Year {
      font-size: 14px;
      font-weight: 700;
      color: $white-primary;
      line-height: 20px;
      text-transform: uppercase;
    }

    &--Day {
      font-size: 30px;
      line-height: 32px;
      text-transform: uppercase;
      font-weight: 700;
      color: $white-primary;
      display: flex;
      flex-direction: column;

      .anticon {
        margin-right: 5px;
        font-size: 30px;
      }
    }
  }

  &__Content {
    width: calc(100% - 85px);
    padding-left: 20px;

    @media(max-width: 767px) {
      width: 100%;
      padding-top: 10px;
      padding-left: 0;
    }

    &--Head {
      display: flex;
      justify-content: space-between;
      align-items: center;

      @media(max-width: 767px) {
        flex-direction: column;
      }

      div {
        display: flex;
        align-items: center;

        @media(max-width: 767px) {
          padding-top: 10px;
          flex-direction: column;
        }

        label {
          margin-left: 20px;
          font-size: 15px;
          display: flex;
          align-items: center;

          span {
            font-weight: 300;
            color: rgb(143, 143, 143);
            margin-right: 5px;

            strong {
              font-weight: 400;
            }

            &.ant-typography {
              color: #000000;
              margin-right: 0;
              font-weight: 400;
            }
          }

          .anticon {
            margin-right: 5px;
            font-size: 18px;
            color: #cf1322;
          }
        }
      }
    }

    &--Body {
      padding-top: 10px;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;

      @media(max-width: 767px) {
        text-align: center;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }

      label {
        font-size: 14px;
        font-weight: 300;
        color: #939393;
      }

      p {
        font-size: 18px;
        margin: 0;
        font-weight: 600;
      }

      &__Action {
        @media(max-width: 767px) {
          padding-top: 10px;
        }

        button {
          margin-left: 10px;

          @media(max-width: 767px) {
            margin: 5px 5px;
          }
        }
      }
    }
  }
}

.sessionStatus {
  display: flex;

  mark {
    font-size: 14px;
    padding: 7px 10px !important;
    line-height: 14px;
  }
}

.quickAccessListBlock {
  .ant-list-item {
    border: 0 !important;
    padding-right: 0 !important;
  }
}

.sessions-list .ant-list-item {
  border: 0 !important;
  padding-right: 0 !important;
}

.invoiceList {
  border: 0 !important;
  padding-right: 0 !important;
}

.ant-btn {
  border-radius: 5px !important;
}

.sessions-list .ant-collapse-content-box {
  padding-top: 0 !important;
}

.search-container .ant-pro-card-body {
  padding: 15px !important;
}

.search-section-right .ant-pro-card-body {
  padding: 15px 15px 0 0 !important;
}