.worlflowTimeline {
    .ant-timeline {
        display: flex;
        width: 100%;
        flex-wrap: wrap;
        padding: 10px 20px !important;

        &-item {
            display: flex;
            flex-direction: column;
            min-width: 150px;
            padding: 0 30px 0 5px;
            flex-grow: 1;
            flex-basis: 0;

            &:first-child &-tail {
                border-top-left-radius: 5px !important;
                border-bottom-left-radius: 5px !important;
            }

            &:last-child &-tail {
                border-top-right-radius: 5px !important;
                border-bottom-right-radius: 5px !important;
            }

            &-tail {
                top: 3px !important;
                left: 0 !important;
                height: 5px !important;
                // width: calc(100% + 100px);
                width: 100%;
                background: #36CFC9;
                border: 0 !important;
            }

            &-head {
                width: 21px !important;
                height: 21px !important;
                background: #36CFC9 !important;
                border: 0 !important;
                border-radius: 30px !important;
                color: #ffffff !important;
                overflow: hidden !important;
                font-size: 11px !important;
                font-weight: 500 !important;
                line-height: 20px !important;
                padding: 0 !important;
                margin-left: 28px;
            }

            &-content {
                top: 0 !important;
                margin: 0 !important;
                display: flex;
                flex-direction: column;
                padding-top: 25px;

                &:before {
                    content: "";
                    position: absolute;
                    left: 26px;
                    top: 15px;
                    width: 3px;
                    height: calc(100% - 42px);
                    background: #43cfc9;
                    z-index: 9;
                }
            }

            &.day-of-session>&-head {
                width: auto !important;
                left: -15px !important;
                transform: none !important;
                top: -6px !important;
                padding: 0 7px !important;
                background: #00474F !important;
                font-size: 10px !important;
            }

            &.day-of-session {
                &:before {
                    content: "";
                    position: absolute;
                    left: 30px;
                    top: -26px;
                    width: 5px;
                    height: 100vh;
                    background: yellow;
                    z-index: 10;
                }
            }

            &.session-created>&-head {
                width: auto !important;
                left: -15px !important;
                transform: none !important;
                top: -6px !important;
                padding: 0 7px !important;
                color: #00474F !important;
                background: #36cfc9 !important;
                font-size: 10px !important;
            }
        }
    }
}

.trigger {
    display: flex;
    position: relative;
    padding: 8px 8px 8px 36px;
    background: #00474F;
    margin-bottom: 15px;
    border-radius: 5px;
    font-size: 12px;
    line-height: 11px;
    align-items: center;
    color: #ffffff !important;

    &:before {
        content: "";
        position: absolute;
        top: calc(50% - 3.5px);
        left: 24px;
        width: 7px;
        height: 7px;
        border-radius: 50%;
        background: #43cfc9;
    }

    .anticon {
        font-size: 14px;
        position: absolute;
        left: 8px;
        top: 8px;
    }
}

.ant-timeline-item-last>.ant-timeline-item-tail {
    display: block !important;
}