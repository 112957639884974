@import "~react-image-gallery/styles/scss/image-gallery.scss";

.slideViewer {
  width: 100%;
  height: 100vh;
  background-color: rgba(23, 33, 49, 0.918);
  z-index: 99999;
  position: absolute;
  right: 0;
  bottom: 0;

  .slideViewerInnerWrapper {
    width: 100%;
    height: 100vh;
    position: relative;
  }

  .topActionOuterWrapper {
    top: 33px;
    z-index: 999999999;
    position: absolute;
      left: 0;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      .topActionInnerWrapper {
        background-color: rgb(241, 241, 241);
        width: 300px;
        border-radius: 25px;
        height: 27px;
        display: flex;
      justify-content: center;
      align-items: center;
      .actions {
        flex: 5;
        height: 100%;
      }
      .closeBtn {
        flex: 2;
        background-color: rgb(218, 2, 2);
        height: 100%;
        border-top-right-radius: 25px;
        border-bottom-right-radius: 25px;
        display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      color: white;
      font-size: 0.7rem;
      font-weight: 600;
      }
      }
  }

  .picture_options {
    background-color: rgba(22, 22, 22, 0);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;


    .otherActions {
      display: flex;
      justify-content: center;
      align-items: center;

      .gridAction {
        background-color: rgba(255, 255, 255, 0);
        color: rgb(17, 17, 17);
        outline: none;
        width: 40px;
        padding: 7px 0;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1rem;
        cursor: pointer;
        border: none;

        &:hover {
          width: 40px;
          height: 100%;
          font-size: 1rem;
        }
      }
    }
  }

  &:hover {
    .picture_options {
      opacity: 1;
    }
  }
  overflow-y: hidden;
  .image-gallery-content.bottom {

    padding-top: 10vh;

    .image-gallery-image {
      height: 75vh !important;
    }

    &.fullscreen {

      padding-top: 0vh;
      padding-bottom: 0vh;
      .image-gallery-image {
        height: 90vh !important;
      }
    }

  }
}