@import "components/design/_variables";

.schedule-calendar-header {
  text-align: center;
  font-weight: 700;
  color: $light;
  text-transform: uppercase;
  background-color: #fff;

  @media (min-width: 768px) {
    font-size: 0.75rem;
    padding: 1rem 0;
    background-color: $lightest;
    border-bottom: 1px solid $lighter;  
  }

  @media (max-width: 767px) {
    font-size: 0.625rem;
    padding: 0.75rem 0;
  }
}