.payment-iframe {
  border: 0;
  width: 100%;
  height: 100%;
}

.btn-action {
  @media(max-width: 767px) {
    height: 28px !important;
    padding: 0 1.5rem !important;

    span {
      display: flex !important;
      font-size: 12px !important;
      line-height: 11px !important;
    }
  }
}

.invoiceDrawerTitle {
  @media(max-width: 767px) {
    text-align: right;
  }
}

.priceListModal {
  .ant-modal {
    &-close {
      background: #ffffff;

      &-x {
        width: 35px !important;
        height: 36px !important;
        line-height: 35px;
      }
    }

    &-title {
      h3 {
        font-size: 18px;
        margin: 0;
      }

      .btn-action {
        padding: 0 20px !important;
        height: 34px !important;
        margin-right: 15px;
      }

      .ant-row {
        align-items: center;
      }
    }
  }
}

.payment-iframe-container {
  .ant-spin-nested-loading {
    height: 100%;
    .ant-spin-container {
      height: 100%;
    }
  }
}

.ClientDetails {
  .ant-btn {
    display: none !important;
  }
}