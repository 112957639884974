$light: #DBDBDB;
$lighter: #eee;
$lightest: #fafafa;
$dark: #2A2E34;
$darker: #1F2327;
// $primary: #5DAEFF;
$primary: rgb(22, 66, 91);
$primary-light: #AFD8FF;
$primary-lighter: #EFF8FF;
$primary-dark: #398EE3;
$primary-gradient: linear-gradient(to right, $primary, $primary-dark);
$secondary: #FFAE5D;
$warning: #FF6262;
$warning-light: #FFC3C3;
$warning-lighter: #FFEBEB;
$warning-lightest: #FFF4F4;
$warning-gradient: linear-gradient(to right, $warning, $secondary);
$boder-radius: 0.375rem;
$border-color-default: $light;
$input-icon-size: 1.5rem;
$card-shadow: 0 3px 30px rgba(0,0,0,0.08);
$nav-padding: 1.875rem;
$nav-padding-sm: 1rem;
$nav-header-height: 5rem;
$nav-width: 240px;
$title-size-1: 1.875rem;
$title-size-1-sm: 1.5rem;
$form-group-padding: 2.5rem;
$form-group-padding-sm: 2rem;
$white-primary: #ffffff;
