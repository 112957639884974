@import "components/design/_variables";

.calendar-year-container {
  height: 100%;

  .swiper-container,
  .swiper-wrapper,
  .swiper-slide,
  .ant-picker-calendar-full,
  .ant-picker-panel,
  .ant-picker-month-panel,
  .ant-picker-body,
  .ant-picker-content {
    height: 100% !important;
    margin: 0;
    padding: 0;
  }

  .ant-picker-calendar {
    .ant-picker-panel {
      .ant-picker-body {
        padding: 0;

        .ant-picker-cell {
          border-top: 1px solid $lighter;
          border-left: 1px solid $lighter;

          &:first-child {
            border-left: 0;
          }
        }

        .ant-picker-cell-selected {
          .ant-picker-calendar-date {
            border-color: transparent;
            background-color: #fff;

            .ant-picker-calendar-date-value {
              color: rgba(0, 0, 0, 0.85);
            }  
          }
        }

        .ant-picker-calendar-date {
          height: 100%;
          margin: 0;
          text-align: left;
          padding: 1rem;
          border-top-color: transparent;
        }
      }
    }

    &.schedule-calendar-year-current {
      .ant-picker-panel {
        .ant-picker-cell-selected {
          .ant-picker-calendar-date {
            border-color: #1890ff !important;
            background-color: #e6f7ff !important;
          }
        }
      }
    }  
  }
}