@import "components/design/_variables";

.nav-branding {
  position: relative;

  &:not(.isOnHeader) {
    padding-left: $nav-padding;
    padding-right: $nav-padding;
    background-color: white;
    justify-content: center;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    border-right: 0.25px solid rgba(0, 0, 0, 0.1);
    height: $nav-header-height;
  }

  .ant-image {
    display: block;

    img {
      width: auto;
      display: block;
    }
  }

  .ant-col {
    position: static;
  }

  .ant-btn {
    &.main-navigation-toggle {
      border: 0;
      box-shadow: none;
      background: transparent;
      margin-right: 1rem;
    }

    &.main-navigation-close {
      border: 0;
      color: #fff;
      background: $dark;
      top: 50%;
      right: 0;
      position: absolute;
      width: 40px;
      height: 40px;
      opacity: 1;
      box-shadow: none;
      transform: translate(0px, -50%);
      transition: all ease-in-out 0.3s;
      transition-delay: 0.3s;

      .active & {
        opacity: 1;
        transform: translate(14px, -50%);
      }

      @media (min-width: 768px) {
        display: none;
      }

      &:focus,
      &:hover,
      &:active {
        color: #fff;
        background: $dark;
      }

      .anticon {
        transform: translateX(2px);
      }
    }
  }

  .brandingText {
    font-weight: 600;
    padding: 0 2.5rem;
    text-align: center;
    @media (max-width: 438px) {
      padding: 10px 0 0 0;
    }
  }
}
