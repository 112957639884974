.ant-select {
    width: 100% !important;
}

.Messages {
    cursor: pointer;
    padding: 7.5px;
    background: #ffffff;
    box-shadow: 0 0 4px 0 rgb(0 0 0 / 10%);
    border-radius: 10px;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;

    &.Selected {
        background: rgb(240,241,242);

        .Messages__Date {
            background: #fff;
        }
    }

    &__Icon {
        width: 45px;
        height: 45px;
        border-radius: 7px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 22px;
        background: rgb(146, 176, 182);
        color: #ffffff;
        &.text {
            background: #13c2c2;
        }
    }

    &__Content {
        width: calc(100% - 45px);
        padding: 0px 0 0 10px !important;

        &--Head {
            display: flex;
            padding: 0 !important;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 0;

            h4.ant-typography {
                font-size: 15px;
                font-weight: 700;
                margin: 0;
                display: flex;
                align-items: center;
                white-space: nowrap;

                .ant-tag {
                    font-size: 10px;
                    font-weight: 500;
                    padding: 3px 5px;
                    line-height: 10px;
                    margin-left: 10px;
                    border: 0;

                    &.text {
                        color: #fff;
                        background: #13c2c2;
                    }

                    &.email {
                        color: #fff;
                        background: #1890ff;
                    }
                }
            }

            .Messages__Date {
                font-size: 9px;
                font-weight: 700;
                padding: 5px;
                line-height: 12px;
                border-radius: 5px;
                background: rgb(255,229,143);
                color: #002766;

                .anticon {
                    margin-right: 5px;
                }
            }
        }
    }

    &__Description {
        display: flex;
        margin: 0;
        font-size: 12px;
        line-height: 24px;
        color: #8c8c8c !important;
        height: 18px;
        overflow: hidden;
    }
}

.messagesGrid__Item {
    padding: 7.5px;
}


.MD__Panel {
    position: relative;
    display: flex;
    flex-direction: column;
    background: #fff;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    padding: 0 !important;

    &--Head {
        padding: 20px !important;
        border-bottom: 1px solid #e4e4e4;
        display: flex;
        justify-content: space-between;

        @media(max-width: 767px) {
            padding-top: 65px !important;
        }

        &__Left {
            padding: 0 10px 0 0 !important;
        }

        &__Right {
            padding: 0 0 0 10px !important;

            .ant-btn {
                width: 36px;
                height: 36px;
                padding: 0 !important;
                background: #40A9FF;
                color: #ffffff;
                font-size: 16px;
                box-shadow: 0 3px 7px 0 rgb(64 169 255 / 45%);
                border: 0;
            }
        }

        &__Subject {
            padding: 0 0 15px !important;

            .form-control-label {
                font-size: 12px;
                line-height: 16px;
                font-weight: 500;
            }

            .ant-row {
                .ant-col {

                    &:first-child
                   {
                        display: none !important;
                    }

                    &.ant-col-xs-10 {
                        width: 100%;
                        flex: 0 0 100%;
                        max-width: 100%;

                        div {
                            font-size: 20px;
                            line-height: 20px;
                            font-weight: 700;
                            text-transform: capitalize;
                        }
                    }
                }
            }

            .ant-typography {
                font-size: 28px;
                font-weight: bold;
                margin: 0;
            }
        }

        &__Detail {
            display: flex;
            flex-wrap: wrap;
            padding: 0 !important;
            align-items: center;

            &--Icon {
                width: 50px;
                height: 50px;
                background: rgb(183, 174, 162);
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 22px;
                color: white;
                border-radius: 10px;
            }

            &--Data {
                padding: 0 0 0 10px !important;
                display: flex;
                flex-direction: column;

                .ant-typography {
                    font-weight: 700;
                    font-size: 12px;
                    line-height: 16px;

                    b {
                        font-weight: 300;
                    }
                }
            }
        }
    }

    &--Body {
        padding: 20px !important;
        height: calc(100vh - 283px);
        overflow: auto;

        &__Title {
            padding: 0 0 20px !important;

            h4.ant-typography {
                font-size: 20px;
                line-height: 24px;
                font-weight: 700;
                color: #000000;
                margin: 0;
            }

            span.ant-typography {
                color: #7C7C7C;
                font-size: 14px;
                font-weight: 300;
                line-height: 13px;
            }
        }


        &__Description {
            padding: 0 !important;

            span.ant-typography {
                font-size: 16px;
                line-height: 26px;
                color: #404040;
            }
        }
    }
}

.Message__List {
    height: calc(100vh - 190px);
    overflow: auto;
}

.Message_Detail {
    @media(max-width:767px) {
        position: fixed !important;
        width: 100%;
        left: 0;
        top: 0;
        background: #ffffff;
        z-index: 100;
        height: 100%;
        padding: 20px;
    }
}

.BackButton {
    position: absolute !important;
    left: 12px;
    top: 15px;
    display: none;

    @media(max-width: 767px) {
        display: block;
    }

    .ant-btn {
        padding: 0;
        line-height: 0;
        width: 40px;
        height: 40px;
        background: #e6f7ff;
        color: #022866;
        border-radius: 10px !important;
        border: 0;
        z-index: 10;
        font-size: 23px;
        line-height: 27px;
    }
}