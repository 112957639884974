@import "components/design/_variables";

body {
  background-image: linear-gradient(#EDEDEB, rgba(255,255,255,0), rgba(255,255,255,0));
}

.ant-layout-content.session-info {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  padding-bottom: 4rem;

  &::before {
    content: '';
    display: block;
    width: 100%;
    height: 30%;
    z-index: 0;
    left: 0;
    bottom: 0;
    position: absolute;
    background-image: linear-gradient(#fff, rgba(255,255,255,0), rgba(255,255,255,0));
  }

  &::after {
    content: '';
    display: block;
    width: 100%;
    height: 20%;
    z-index: -1;
    left: 0;
    bottom: 0;
    position: absolute;
    background: url("//assets/bg-1.jpg") 50% 50% / cover no-repeat;

    @media (min-width: 500px) {
      height: 30%;
    }
  }

  // .container{
  //   width: 100%;
  // }

  .ant-card{
    margin-bottom: 10px;
    width: 350px;
    // text-align: center;
  }

  .ant-card-extra {
    padding: 0;
  }

  .session-select{
    padding: 24px 0px;
    width: 100%;
  }
  .action-buttons{
    // padding: 10px;
    .ant-col{
      // padding: 10px;
      text-align: center;
      text-transform: uppercase;
      font-size: 0.5pc;
      .ant-btn{
        width: 100%;
        text-align: left;
      }
    }
  }

  .quick-links{
    padding: 10px 0px;
    // background-color: #EEE;
    margin-top: 10px;
    // a.ant-btn{height: 16px;}
  }

  .copyright {
    font-size: 0.75rem;
    margin-top: 1.75rem;

    @media (min-width: 500px) {
      font-size: 0.875rem;
    }

    a {
      color: inherit;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}