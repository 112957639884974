.productListOuterWrapper {
  $small: 450px;
  $medium: 768px;
  transition: 0.1s!important;
  width: 100%;
  @media screen and (max-width: $medium) {
    padding: 0px 0px 50px 20px;
    margin-top: -15px;
  }
  .navbar {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 50px 0px 30px 50px;
    @media screen and (max-width: $medium) {
      padding: 70px 0px 50px 20px;
    }
    .navBackBtn {
      background-color: white;
      outline: none;
      border: none;
      cursor: pointer;
      font-size: 1.3rem;
    }
    h4 {
      padding-top: 5px;
      padding-left: 15px;
      font-size: 1.4rem;
    }
  }
  .filterWrapper {
    padding: 0px 50px 30px 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media screen and (max-width: $medium) {
      flex-direction: column;
      padding: 0px 20px 30px 20px;
    }
    .filter {
      // background-color: green;
      width: 40%;
      font-size: 0.8rem;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      .filterLabel {
        flex: 2;
      }
      .filterInput {
        padding-left: 14%;
        flex: 5;
      }
      @media screen and (max-width: $medium) {
        width: 100%;
        font-size: 1rem;
        margin-bottom: 15px;
        .filterLabel {
          margin-left: -25px;
          flex: 1;
          // background-color: yellow;
          align-items: center;
          display: flex;
        justify-content: flex-end;
        }
        .filterInput {
          padding-left: 5%;
          flex: 1;
          display: flex;
        align-items: center;
        justify-content: flex-start;
        }
      }
    }
  }
  .productList {
    padding: 0px 30px 30px 30px;
    display: flex;
    height: 70vh;
    overflow-y: scroll;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    .productWrapper {
      padding: 0px 10px 10px 10px;
      width: 50%;
      @media screen and (max-width: $medium) {
        width: 100%;
        border: 1px solid rgb(211, 211, 211);
        border-radius: 5px;
        padding: 20px 20px 0px 20px;
        background-color: rgb(197, 227, 255);
        margin-bottom: 15px;
        .productSelectBtn {
          background-color: rgb(0, 68, 255)!important;
          box-shadow: 0px 0px 30px rgba(0,0,0,0.15)!important;
        }
      }
      .product {
        padding: 7px 10px 15px 10px;
        border-radius: 5px;
        &.withOutThumbnail {
          display: flex;
          justify-content: flex-start;
          align-items: flex-start;
          flex-direction: column;
        }
        &:hover {
          background-color: rgba(197, 227, 255, 0.287);
          .productSelectBtn {
            background-color: rgb(0, 68, 255);
            box-shadow: 0px 0px 30px rgba(0,0,0,0.15);
          }
        }
        h3 {
          margin-bottom: 7px;
          font-weight: 600;
        }
        .price {
          color: rgb(0, 132, 255);
          font-size: 0.9rem;
        }
        .description {
          font-size: 0.9rem;
        }
        .productSelectBtn {
          border: none;
          font-weight: 600;
          background-color: rgb(255, 255, 255);
          color: white;
          padding: 5px 45px 5px 45px;
          font-size: 0.7rem;
          cursor: pointer;
          text-align: center;
          border-radius: 4px;
        }
      }
    }
  }
}