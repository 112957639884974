.ant-table-wrapper .ant-table {
    overflow: auto;
}

.no-data-found {
    display: flex;
    justify-content: center;
    font-size: 14px;
    font-style: italic;
    color: #bfbfbf;
    align-items: center;
    min-height: 100px;
}


.pro-table {
    ::-webkit-scrollbar {
        width: auto;
    }
}

.clientGraphicView {


    &__Action {
        display: flex !important;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 20px;
        h4 {
            margin: 0 !important;
        }
    }

    .clientGraphical {
        background: #F1F2F4;
        box-shadow: 0 0 3px 0 rgb(0 0 0 / 20%);
        width: 100%;
        padding: 20px;
        border-radius: 5px;
    }

    .ant-list-item {
        border: 0 !important;
    }

    .clientGraphical {
        &--Icon {
            align-items: center;
            display: flex;
            justify-content: center;

            .anticon.anticon-user {
                display: flex;
                width: 50px;
                height: 50px;
                background: #5aabfd;
                border-radius: 50px;
                align-items: center;
                justify-content: center;
                font-size: 26px;
                color: #ffffff;
            }
        }

        &--Details {
            padding-left: 15px;

            &__Top {
                justify-content: space-between;
                align-items: center;
                margin-bottom: 2px;

                .ant-col {
                    display: flex;
                }
            }

            &__No {
                padding: 3px 5px;
                background: #ffe58f;
                font-size: 12px;
                border-radius: 5px;
                line-height: 11px;
            }

            &__Date {
                margin-left: auto;
            }

            &__Name {
                font-size: 17px;
                margin: 0;
            }

            &__Address {
                font-size: 13px;
                color: #949494;
            }
        }
    }
}