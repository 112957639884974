.productCategoryListOuterWrapper {
  $small: 450px;
  $medium: 768px;
  transition: 0.1s!important;
  .navbar {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 50px 0px 50px 70px;
    @media screen and (max-width: $medium) {
      padding: 70px 0px 50px 20px;
    }
    .navBackBtn {
      background-color: white;
      outline: none;
      border: none;
      cursor: pointer;
      font-size: 1.3rem;
    }
    h4 {
      padding-top: 5px;
      padding-left: 15px;
      font-size: 1.4rem;
    }
  }
  .categoryList {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    height: 78vh;
    overflow-y: scroll;
    padding: 0 20px 0 90px;
    @media screen and (max-width: $medium) {
      padding: 0 20px 0 30px;
    }
    .category {
      display: flex;
      justify-content: center;;
      align-items: center;
      // background-color: rgb(231, 231, 231);
      height: 300px;
      width: 100%;
      margin-bottom: 5rem;
      @media screen and (max-width: $medium) {
        flex-direction: column-reverse;
        border: 1px solid rgb(211, 211, 211);
        border-radius: 5px;
        padding: 20px 20px 10px 20px;
        margin-bottom: 2rem;
      }
      .infoPart {
        flex: 5;
        margin-top: 2rem;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
        @media screen and (max-width: $medium) {
          width: 100%;
        }
        h5 {
          font-size: 1.1rem;
        }
        p {
          font-size: 0.8rem;
        }
        button {
          background-color: white;
          border: 1px solid #3E91E6;
          color: #3E91E6;
          border-radius: 3px;
          padding: 3px 25px;
          cursor: pointer;
          font-size: 0.7rem;
          @media screen and (max-width: $medium) {
            width: 100%;
            padding: 8px 25px;
          }
          &:hover {
            background-color: #3E91E6;
            border: 1px solid #3E91E6;
            color: white;
            border-radius: 3px;
            padding: 3px 25px;
            cursor: pointer;
            font-size: 0.7rem;
          }
        }
      }
      .graphicPart {
        flex: 7;
        @media screen and (max-width: $medium) {
          display: flex;
          justify-content: center;
          align-items: center;
        }
        img {
          margin-left: 29px;
          width: 70%;
          @media screen and (max-width: $medium) {
            width: unset;
            height: 150px;
            margin-left: 0px;
          }
        }
      }
    }
  }
}