@import "components/design/_variables";

.calendar-mobile {
  height: 100%;
  display: flex;
  flex-direction: column;

  .calendar-select-container,
  .calendar-week-container {
    flex: 0 0 20rem;
    z-index: 2;
    position: relative;
    box-shadow: $card-shadow;
  }
  
  .calendar-week-container {
    flex: 0 0 5rem;
  }

  .schedule-calendar-header {
    z-index: 3;
    position: relative;
  }
}

.calendar-mobile-head {
  flex: 0 0 4rem;
  padding-right: $nav-padding-sm;
  padding-left: $nav-padding-sm;

  .ant-row {
    height: 100%;
  }

  .btn-header-title {
    margin-left: -0.5rem;
  }

  .ant-space {
    margin-right: -0.5rem;
  }
}
