.print-confirmation{
  .textRight strong{
    min-width: 75px;
  }
}

.participant {
  margin: 0px !important;
}

.fullWidth {
  padding: 10px 0 20px;
}

.textRight {
  text-align: right !important;
}