@import "components/design/_variables";

body {
  background-image: linear-gradient(#EDEDEB, rgba(255,255,255,0), rgba(255,255,255,0));
}

.ant-layout-content.login-module {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  padding-bottom: 4rem;

  &::before {
    content: '';
    display: block;
    width: 100%;
    height: 30%;
    z-index: 0;
    left: 0;
    bottom: 0;
    position: absolute;
    background-image: linear-gradient(#fff, rgba(255,255,255,0), rgba(255,255,255,0));
  }

  &::after {
    content: '';
    display: block;
    width: 100%;
    height: 20%;
    z-index: -1;
    left: 0;
    bottom: 0;
    position: absolute;
    background: url("../../static/City.jpg") 50% 50% / cover no-repeat;

    @media (min-width: 500px) {
      height: 30%;
    }
  }

  .action {
    margin-top: 1rem;
    flex-direction: column;

    @media (min-width: 500px) {
      margin-top: 1.5rem;
    }

    @media (min-width: 500px) {
      justify-content: space-between;
      flex-direction: row;
    }

    .action-btn {
      @media (min-width: 500px) {
        order: 2;
        width: 50%;
        flex: 0 0 50%;
      }

      @media (max-width: 499px) {
        width: 100%;
        flex: 0 0 100%;
        margin-bottom: 1rem;
      }
    }
  }

  .container {
    padding: 2rem 1.25rem;
    max-width: 32rem;
  }
  
  .logo {
    margin-bottom: 1rem;
  }

  .copyright {
    font-size: 0.75rem;
    margin-top: 1.75rem;

    @media (min-width: 500px) {
      font-size: 0.875rem;
    }

    a {
      color: inherit;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .ant-card {
    border: 0;
    font-size: 1rem;
    background-color: transparent;

    @media (min-width: 500px) {
      border-radius: $boder-radius;
      box-shadow: $card-shadow;
      background-color: #fff;
    }

    .ant-card-body {
      @media (min-width: 500px) {
        padding: 2.75rem 3.125rem;
      }

      @media (max-width: 499px) {
        padding: 0;
      }  

      h1,
      p {
        margin-bottom: 1.75rem;
      }
    }
  }
}