@import "components/design/_variables";

// .navigation-profile {
//   padding: 0 $nav-padding;
//   height: $nav-header-height;
//   background-color: darken($dark, 5%);
//   cursor: pointer;
//   transition: background-color ease-in-out 0.3s;

//   &:hover {
//     background-color: darken($dark, 8%);
//   }

//   .ant-row {
//     height: 100%;
//     align-items: center;
//   }

//   .details {
//     color: #fff;
//     padding-left: 1rem;
//     flex-basis: 0;
//     flex-grow: 1;
//     max-width: 100%;

//     span {
//       display: block;

//       &.name {
//         font-size: 1.2rem;
//         font-weight: bold;
//         line-height: 1.2;
//         text-transform: capitalize;
//       }

//       &.title {
//         opacity: 0.5;
//       }
//     }
//   }
// }


.navigation-profile {
  padding: 20px;

  .ant-row {
    background: #ffffff;
    color: #000;
    display: flex;
    align-items: center;
    padding: 5px;
    border-radius: 10px;

  }

  &__avatar {
    width: 40px;
    height: 40px;
    border-radius: 10px;
    background: rgb(183, 174, 162);
    overflow: hidden;

    .ant-avatar {
      width: 100% !important;
      height: 100% !important;
      font-size: 24px !important;
      line-height: 40px !important;
      background-color: rgb(183, 174, 162) !important;
    }
  }

  &__details {
    display: flex;
    flex-direction: column;
    padding-left: 10px;

    .name {
      font-size: 14px;
      font-weight: 700;
      line-height: 12px;
      margin-bottom: 4px;
    }

    .title {
      font-size: 12px;
      font-weight: 400;
      color: rgb(38 38 38 / 40%);
      line-height: 12px;
    }
  }
}