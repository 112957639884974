.ant-btn {
  &.btn-action {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 2.75rem; 
    border: solid 1px white;

    &:not(.ant-btn-block) {
      padding-left: 2rem;
      padding-right: 2rem;  
    }

    &.ant-btn-lg {
      font-size: 1.125rem;
      height: 3.75rem;  
    }

    @media (max-width: 479px) {
      font-size: 1rem;
    }

    span {
      z-index: 2;
      position: relative;
      font-weight: bold;
      text-transform: uppercase;
    }

    &.ant-btn-primary {
      background-image: $primary-gradient;

      &.ant-btn-dangerous {
        background-image: $warning-gradient;
      }

      &[disabled] {
        color: #fff;
        opacity: 0.5;
      }  

      &::before {
        content: '';
        display: block;
        opacity: 0;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        border-radius: inherit;
        position: absolute;
        background:#000;
        transition: opacity ease-in-out 0.3s;
      }

      &:hover {
        &::before {
          opacity: 0.1;
        }
      }

      &:focus {
        box-shadow: 0 0 0 3px rgba($primary, 0.4);
      }

      .anticon {
        opacity: 1;
      }
    }
  }

  &.btn-header-title {
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;

    > .anticon {
      margin-right: 1rem;
    }

    > .page-header-title {
      ~ .anticon {
        margin-right: 0;
        margin-left: 1rem;
      }
    }
  }
}

.ant-btn-icon-only {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 4px;

  svg {
    width: 1.25rem;
    height: 1.25rem;
    top: 1px;
    position: relative;
  }

  &:hover {
    box-shadow: 0 2px 3px rgba($primary-light, 0.2);
  }
}