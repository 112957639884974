.ant-list.nav-list {
  .ant-list-item-meta-title {
    font-weight: 400;
  }
  .ant-list-item-meta-avatar {
    .anticon {
      font-size: 1.25rem;
    }
  }
}

.nav-list-clickable{
  cursor: pointer;
}