.ant-popover {
  &.navigation-popover-container {
    padding-top: 0;

    .ant-popover-arrow {
      display: none;
    }
  
    .ant-popover-inner-content {
      padding: 0;
    }
  }  
}

.ant-dropdown-menu,
.ant-menu {
  &.navigation-popover {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    border-radius: $boder-radius;

    .ant-menu-item,
    .ant-dropdown-menu-item {
      font-size: 1rem;
      display: flex;
      flex-direction: row;
      align-items: center;
      margin: 0;
      padding: 0.75rem 1.5rem;

      &.ant-menu-item-selected {
        color: inherit;
        background-color: transparent;
      }

      &:active {
        color: inherit;
      }

      &:focus,
      &:hover {
        color: inherit;
        background-color: $lightest;

        .anticon {
          svg {
            opacity: 1;
          }
        }
      }

      .anticon {
        width: 1.5rem;
        margin-right: 1rem;

        svg {
          width: 1.25rem;
          height: 1.25rem;
          opacity: 0.5;
          transition: opacity ease-in-out 0.3s;
        }

        &.types-appointment,
        &.types-session {
          position: relative;

          &::after {
            content: '';
            display: block;
            right: 0;
            bottom: -2px;
            width: 8px;
            height: 8px;
            position: absolute;
            border-radius: 50%;
          }
        }

        &.types-appointment {
          &::after {
            background-color: $primary;
          }
        }

        &.types-session {
          &::after {
            background-color: $secondary;
          }
        }
      }
    }
  }
}
