.cartLoaderWrapper {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(41, 41, 41, 0.794);
  .bottomPanel {
    position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 80px;
  background-color: rgb(37, 37, 37);
  color: white;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 35px;
  .loaderWarpper {
    width: 50px;

  .loader,
  .loader:after {
    border-radius: 100%;
    width: 12em;
    height: 12em;
  }

  .loader {
    margin: auto;
    font-size: 2px;
    position: relative;
    text-indent: -9999em;
    border-top: 1.1em solid rgba(56, 56, 56, 0.2);
    border-right: 1.1em solid rgba(69, 69, 69, 0.2);
    border-bottom: 1.1em solid rgba(69, 69, 69, 0.2);
    border-left: 1.1em solid #fdfcfc;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: load8 1.3s infinite linear;
    animation: load8 1.3s infinite linear;
  }

  @-webkit-keyframes load8 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }

    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }

  @keyframes load8 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }

    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  }  
  }
}