@import "components/design/_variables";

.calendar-year-full {
  > .ant-row {
    margin-left: -1rem;
    margin-right: -1rem;  
  }

  .calendar-year-full-month {
    padding-left: 1rem;
    padding-right: 1rem;

    thead {
      display: none;
    }

    tbody {
      td {
        font-size: 0.688rem;
        line-height: 1;

        span {
          z-index: 2;
          position: relative;
        }

        &::before {
          border-radius: 50%;
          left: 50%;
          right: auto;
          transform: translate(-50%, -50%);
          border: 1px solid transparent;
          background-color: transparent;
          transition: background-color ease-in-out 0.3s;

          @media (min-width: 1160px) {
            width: 24px;
            height: 24px;
          }

          @media (max-width: 1159px) {
            width: 20px;
            height: 20px;
          }
        }

        &.ant-picker-cell-today {
          &::before {
            border-color: $primary;
            background-color: transparent;
          }  
        }

        &:not(.ant-picker-cell-in-view) {
          visibility: hidden;
        }

        &:hover {
          &::before {
            border-color: #eee;
            background-color: #eee;
          }
        }
      }
    }

    &.active {
      tbody {
        td {
          &.ant-picker-cell-selected {
            color: #fff;
  
            &::before {
              border-color: $primary;
              background-color: $primary;
            }  
          }  
        }
      }
    }

    .ant-picker-calendar {
      .ant-picker-content {
        @media (min-width: 1160px) {
          height: 150px;
        }

        @media (min-width: 992px) and (max-width: 1159px) {
          height: 120px;
        }

        @media (max-width: 991px) {
          height: 100px;
        }
      }
    }

    h4 {
      font-size: 0.875rem;
      text-align: left;
      font-weight: 400;
      margin-bottom: 0;
      padding-left: 6px;
    }

    .ant-picker-panel {
      border-top: 0;
    }

    .ant-picker-calendar-date-value {
      display: block;
    }

    .ant-picker-cell {
      text-align: center;
    }
  }
}
