.client-tabs .ant-tabs-tab {
    margin: 0 20px 0 10px;
    padding: 8px 20px;
    background-color: rgb(146, 176, 182) !important;
    color: white;
    &:hover {
        color: white;
    }
    &.ant-tabs-tab-active {
        background-color: white !important;
        .ant-tabs-tab-btn {
            color: black !important;
        }
    }
  }

.ant-dropdown-trigger.ant-dropdown-link {
    color: #708D65;
}