.ant-typography {
  &.page-header-title {
    font-size: $title-size-1;
    font-weight: 600;
    margin-bottom: 0;

    @media (max-width: 479px) {
      font-size: $title-size-1-sm;
    }
  }
}

// span.ant-descriptions-item-content {
//   justify-content: end;
// }
