.productBrowserOuterWrapper {
  h3 {
    text-align: center;
    margin-bottom: 3rem;
    margin-top: 1rem;
  }
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  .productCategories {
    width: 80%;
    height: 60vh;
    overflow-y: scroll;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    .productCategoryWrapper {
      flex: 1;
      padding: 10px 25px;
      .productCategory {
        width: 200px;
        flex-direction: column;
        height: 250px;
        border: 1px solid rgb(0, 104, 240);
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          width: 50px;
          border-radius: 600px;
        }
      }
    }
  }
}

.cartLoaderWrapper {
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .loader,
  .loader:after {
    border-radius: 50%;
    width: 10em;
    height: 10em;
  }

  .loader {
    margin: auto;
    font-size: 3px;
    position: relative;
    text-indent: -9999em;
    border-top: 1.1em solid rgba(69, 69, 69, 0.2);
    border-right: 1.1em solid rgba(69, 69, 69, 0.2);
    border-bottom: 1.1em solid rgba(69, 69, 69, 0.2);
    border-left: 1.1em solid #454545;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: load8 1.1s infinite linear;
    animation: load8 1.1s infinite linear;
  }

  @-webkit-keyframes load8 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }

    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }

  @keyframes load8 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }

    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
}
