@import "components/design/_variables";

.ant-menu {
  &.main-navigation {
    &.ant-menu.ant-menu-dark {
      background: $dark;
    }

    .ant-menu-item {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 0 $nav-padding;
      margin: 0;

      &[role="heading"] {
        font-size: 0.75rem;
        font-weight: bold;
        text-transform: uppercase;
        min-height: 0;
        margin-top: 1.5rem;
        margin-bottom: 0;
      }

      &[role="menuitem"] {
        cursor: pointer;
        min-height: 3.175rem;
        margin-bottom: 0;

        &:hover {
          background-color: darken($dark, 3%);

          .ant-menu-item-icon {
            opacity: 1;
          }
        }

        &.ant-menu-item-selected {
          background-image: $primary-gradient;

          .ant-menu-item-icon {
            opacity: 1;
          }
        }
      }

      >span {
        display: block;
        width: 100%;
        height: auto;
        line-height: 1;
        font-size: 1rem;
        color: #fff;
        position: relative;
      }

      .ant-badge {
        top: 0;
        right: 0;
        position: absolute;

        .ant-badge-count {
          border: 0;
          box-shadow: none;
          background-color: $warning;
        }
      }

      .ant-menu-item-icon {
        flex: 0 0 $input-icon-size;
        width: $input-icon-size;
        margin-right: 1.5rem;
        opacity: 0.5;
        transition: opacity ease-in-out 0.3s;

        svg {
          width: $input-icon-size;
          height: $input-icon-size;
        }
      }

      &.submenu {
        padding-right: 10px !important;
      }
    }

    .ant-menu-submenu {
      // display: flex;
      // flex-direction: row;
      // align-items: center;
      padding: 0;
      margin: 0;

      .ant-menu-submenu-title {
        border-bottom: 1px solid transparent;
        margin: 0;
        color: #fff;
      }

      &.ant-menu-submenu-open {
        .ant-menu-submenu-title {
          border-color: #010c17;
        }
      }

      &.ant-menu-submenu-selected {
        background-image: $primary-gradient;


        .ant-menu-item-icon {
          opacity: 1;
        }
      }

      &:hover {
        background-color: darken($dark, 3%);

        .ant-menu-item-icon {
          opacity: 1;
        }
      }

      >span {
        display: block;
        width: 100%;
        height: auto;
        line-height: 1;
        font-size: 1rem;
        color: #fff;
        position: relative;
      }

      .ant-badge {
        top: 0;
        right: 0;
        position: absolute;

        .ant-badge-count {
          border: 0;
          box-shadow: none;
          background-color: $warning;
        }
      }

      .ant-menu-submenu-title {
        .ant-menu-item-icon {
          margin-right: 1.5rem;
        }
      }

      .ant-menu-item-icon {
        flex: 0 0 $input-icon-size;
        width: $input-icon-size;
        margin-right: 10px;
        opacity: 0.5;
        transition: opacity ease-in-out 0.3s;

        svg {
          width: $input-icon-size;
          height: $input-icon-size;
        }
      }
    }
  }
}


.submenu {
  &.ant-menu-item-not-selected {
    background: none !important;

    .ant-menu-item-icon {
      opacity: 1;
    }
  }

  &:hover {
    background-color: darken($dark, 3%) !important;
    background-image: $primary-gradient !important;

    .ant-menu-item-icon {
      opacity: 1;
    }
  }
}

// .ant-menu-submenu.ant-menu-submenu-inline.ant-menu-submenu-open {
//   background-image: linear-gradient(to right, #5DAEFF, #398EE3);
// }

.ant-menu-item.locationDropdown {
  background: #bae0ff !important;
  padding: 0 5px !important;
  border-radius: 10px;
  min-height: 30px !important;
  height: 30px !important;
  margin-bottom: 20px !important;

  .ant-menu-item-icon {
    position: absolute !important;
    top: 5px !important;
    left: 8px !important;
    svg {
      width: 18px !important;
    }
  }

  .ant-menu-title-content {
    margin: 0 !important;
  }

  .ant-select-suffix, .anticon.anticon-environment.ant-menu-item-icon {
    color: #001d66 !important;
    opacity: 1;
  }

  .ant-select {
    padding: 0 !important;

    &-selector {
      padding: 0 5px 0 30px !important;
      background: none !important;
      border: 0 !important;
      color: #001d66 !important;
    }

    &-selection {
      &-overflow {
        &-item {}
      }

      &-placeholder {
        left: 0 !important;
        right: 0 !important;
        color: #001d66 !important;
      }
    }
  }
}