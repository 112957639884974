@import "components/design/_variables";

@import "components/design/_variables";

.time-line {
  left: 0;
  width: 6.1rem;
  height: 2px;
  z-index: 2;
  position: absolute;
  background-color: $warning;
}