@import "components/design/_variables";

.ant-select-single .ant-select-selector .ant-select-selection-search-input {
  background-color: #F1F2F4;
}

.ant-form-item-explain {
  margin-top: 0.25rem;
  min-height: inherit !important;

  .form-group & {
    padding-left: 3.5rem;
  }

  > div {
    font-size: 0.875rem;
  }
}

.form-control {
  position: relative;

  input:-webkit-autofill,
  input:-webkit-autofill:hover, 
  input:-webkit-autofill:focus, 
  input:-webkit-autofill:active {
    transition: background-color 5000s;
    -webkit-box-shadow: none;
    -webkit-text-fill-color: #000;
    background-color: none;
  }

  input {
    border-color: transparent !important;
    box-shadow: none !important;
  }

  .ant-picker-range-separator {
    flex: 0 0 5rem;
    max-width: 5rem;
    text-align: center;
  }

  .anticon {
    svg {
      width: $input-icon-size;
      height: $input-icon-size;
    }
  }

  .form-control-label-col {
    padding-top: 0.938rem;
    flex: 0 0 3.5rem;
    max-width: 3.5rem;
  }

  .form-control-content-col {
    flex-grow: 1;
    max-width: 100%;
    position: relative;
  }

  .form-control-label {
    top: 0;
    left: 0;
    width: 100%;
    z-index: 2;
    position: absolute;
    padding: 0.938rem 0.25rem 1rem 0.25rem;
    transform: scale(1) translate(0,0);
    will-change: transform opacity;
    transform-origin: top left;
    transition: transform ease-in-out 0.3s, opacity ease-in-out 0.3s, padding-top ease-in-out 0.3s;
    font-weight: 600;
  }

  .form-control-content {
    border-radius: $boder-radius;
    padding: 1.25rem 0.25rem 0.25rem 0.25rem ;
    background-color: transparent;
    transition: background-color ease-in-out 0.3s;

    .ant-form-item-has-error & {
      background-color: $warning-lightest;
    }

    .ant-input {
      padding-left: 0;
      padding-right: 0;
      transform: translateY(0);
    }

    .ant-select {
      &.ant-select-single {
        .ant-select-selector {
          height: 2.143rem;
          line-height: 2.143rem;
        }
      }

      .ant-select-selector {
        padding-left: 0;
        padding-right: 0;

        .ant-select-selection-search {
          left: 0;
          right: 0;
        }

        .ant-form-item-has-error & {
          &:not(.ant-select-disabled):not(.ant-select-customize-input) {
            border-color: transparent !important;
          }
        }
      }  
    }

    .ant-picker {
      padding: 0.425rem 0 0.125rem 0;
      transform: translateY(0);
      transition: color ease-in-out 0.3s, transform ease-in-out 0.3s;    
    
      input {
        font-size: 1rem;
      }
    }

    .ant-picker-range {
      .ant-picker-active-bar {
        margin-left: 0;
      }
    }
  }

  &.isActive,
  &.isFocus {
    .form-control-label {
      opacity: 0.5;
      padding-bottom: 0;
      transform: scale(0.75) translate(0.125rem, -0.375rem);
    }
  }

  &.isFocus {
    .form-control-content {
      background-color: $lightest;
    }
  }

  &.form-control-rounded {
    background-color: transparent;
    border-radius: $boder-radius;
    border: 1px solid $border-color-default;
    padding: 0.25rem 1rem 0 1rem;
    transition: background-color ease-in-out 0.3s, border-color ease-in-out 0.3s, box-shadow ease-in-out 0.3s;

    .anticon {
      svg {
        width: 1.75rem;
        height: 1.75rem;
      }
    }

    .form-control-label-col {
      padding-top: 0.675rem;
    }

    .form-control-label {
      padding-top: 0.75rem;
      padding-bottom: 0.875rem;
    }

    .form-control-content {
      padding-top: 1rem;
      padding-bottom: 0.25rem;
      background-color: transparent;
    }

    &.isActive,
    &.isFocus {
      .form-control-label {
        padding-top: 0.5rem;
      }
    }
  
    &.isFocus {
      .form-control-content {
        background-color: transparent;
      }
    }
    
    .form-control-label-col {
      flex: 0 0 2.5rem;
      max-width: 2.5rem;
    }

    &.isFocus:not(.ant-form-item-has-error) {
      border-color: $primary-light;
      box-shadow: 0 2px 8px rgba($primary-light, 0.4);
      background-color: #fff;
    }

    .ant-form-item-has-error & {
      border-color: $warning-light;

      &.isFocus {
        border-color: $warning-light;
        box-shadow: 0 2px 8px rgba($warning-light, 0.4);
      }
    }
  }

  &.isDisabled {
    .form-control-label {
      opacity: 0.5;
    }
  }
}