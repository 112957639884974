.checkoutWizardWrapper {
  $small: 450px;
  $medium: 768px;
  width: 100%;
  height: 100vh;
  background-color: white;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 99999999;

  .closeBtn {
    top: 40px;
    left: 40px;
    font-size: 1.5rem;
    cursor: pointer;
    color: red;
    position: absolute;
    @media screen and (max-width: $medium) {
      top: 30px;
    left: 20px;
    }
  }

  .progressBarWrapper {
    * {
      transition: 0.3s!important;
    }
    display: flex;
    justify-content: center;
    align-items: center;

    .progressBar {
      background-color: rgb(231, 231, 231);
      height: 10px;
      width: 80%;

      @media screen and (max-width: $medium) {
        width: 100%;
      }

      border-radius: 5px;

      .progress {
        height: 100%;
        background-color: rgb(51, 146, 255);
        width: 33%;
      }
    }
  }

  .contentArea {
    height: 97vh;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    padding-top: 3rem;
    overflow-y: scroll;
    overflow-x: hidden;

    .secureContent {
      width: 400px;
      display: flex;
      align-items: center;

      @media screen and (max-width: $medium) {
        flex-direction: column;
      }

      .imagePart {
        flex: 1;

        img {
          width: 120px;
        }
      }

      .textPart {
        flex: 5;

        p {
          font-size: 0.8rem;
          margin-top: -10px;
        }

        h2 {
          font-weight: 600;
        }
      }
    }

    .shippingInformation {

      margin-top: 2rem;
      width: 60%;

      @media screen and (max-width: $medium) {
        width: 90%;
      }

      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-direction: column;

      .labelWrapper {
        width: 100%;
        text-align: left;
      }

      .clentAddressWrapper {
        margin-top: 1rem;
        width: 100%;
        text-align: left;

        .clientAddress {
          margin-top: 2rem;
          border: 1px solid rgb(216, 216, 216);
          border-radius: 5px;
          margin-bottom: 2rem;
          padding: 8px 15px;
          font-size: 0.8rem;

          span {
            font-weight: 600;
          }
        }
      }

      .addressForm {
        display: flex;
        width: 100%;
        justify-content: flex-start;
        align-items: center;
        flex-direction: column;
        margin-top: 2rem;
        margin-bottom: 1.5rem;

        .formRow {
          width: 100%;
          display: flex;
          justify-content: flex-start;
          align-items: center;

          @media screen and (max-width: $medium) {
            flex-direction: column;
          }

          .inputWrapper {
            display: flex;
            width: 50%;
            padding: 0 8px 0 0;
            // background-color: blue;
            justify-content: flex-start;
            align-items: flex-start;
            flex-direction: column;
            margin-bottom: 10px;

            @media screen and (max-width: $medium) {
              width: 100%;
              padding: 0;
            }

            p {
              margin-bottom: 2px;
            }

            .label {
              font-size: 0.8rem;
              padding-left: 5px;
              font-weight: 600;
            }

            .errorText {
              font-size: 0.8rem;
              padding-left: 10px;
              color: red;
              height: 20px;
            }
          }
        }
      }

      .deliveryMethods {
        margin: 5px 0 20px 0;
        width: 100%;

        .deliveryMethod {
          width: 100%;
          cursor: pointer;
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 6px 15px;
          border-radius: 5px;
          margin-bottom: 5px;
          border: 1px solid rgb(255, 255, 255);

          &.selected {
            border: 1px solid rgb(0, 75, 250);
            background-color: rgba(175, 206, 255, 0.52);

            .buttonPart {
              font-weight: 600;
            }

            .pricePart {
              font-weight: 600;
            }
          }
        }
      }


    }
  }
  .checkoutWizardBottomNavigator {
    margin-top: 2rem;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;

    .leftPart {
      font-weight: 600;
      font-size: 0.9rem;
      cursor: pointer;
    }

    .rightPart {
      button {
        border: none;
        background-color: rgb(0, 190, 0);
        color: white;
        border-radius: 4px;
        padding: 8px 35px;
        cursor: pointer;
        box-shadow: 2px 2px 35px rgba(0, 0, 0, 0.1);
        font-size: 0.9rem;
        font-weight: 600;

        &:hover {
          background-color: rgb(0, 160, 0);
          box-shadow: 2px 2px 35px rgba(0, 0, 0, 0.061);
        }
      }
    }
  }
}